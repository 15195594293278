import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
    DatePicker,
    TimePicker,
    DateTimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';

export const DateField = (props) => {
    const { customs, value, editable, ...others } = props

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
                fullWidth
                size="small"
                inputVariant="outlined"
                autoOk
                format="MMM d, yyyy"
                value={value ? value : null}
                readOnly={editable == 'false'}
                {...others}
            />
        </MuiPickersUtilsProvider>
    )
}