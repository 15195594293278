import Grid from '@material-ui/core/Grid'

import { categorySelection, entryDescSelection } from '../../components/options/Options'
import { NumFormatInput } from '../../components/NumberFormatCustom'

import { CustomField } from './CustomField'

export const EditorP1 = (props) => {
    const {
        id,
        entry_date,
        entry_title,
        entry_desc,
        income,
        expense,
    } = props.summary ? props.summary : ''

    const editable = props.editable

    return (<Grid container spacing={3}>

        <Grid item xs={12} sm={6}><CustomField label="Date" customs={{ type: 'date', id: id, propkey: 'entry_date', propval: entry_date }} editable={editable} /></Grid>
        <Grid item xs={12} sm={6}><CustomField label="Category" customs={{ type: 'selector', id: id, propkey: 'entry_title', propval: entry_title, options: categorySelection }} editable={editable} /></Grid>
        <Grid item xs={12} sm={12}><CustomField label="Description" customs={{ type: 'selector', id: id, propkey: 'entry_desc', propval: entry_desc, options: entryDescSelection }} editable={editable} /></Grid>
        <Grid item xs={12} sm={6}><CustomField label="Income" customs={{ type: 'freestyle', id: id, propkey: 'income', propval: income, formatter: NumFormatInput }} editable={editable} /></Grid>
        <Grid item xs={12} sm={6}><CustomField label="Expense" customs={{ type: 'freestyle', id: id, propkey: 'expense', propval: expense, formatter: NumFormatInput }} editable={editable} /></Grid>

    </Grid>
    )
}