export const adderLogger = (props) => {
    const status = props[0]
    const error = props[1] ? ' : [' + props[1] + ']' : ''

    const adder = {
        'success': ['A new entry has been added successfully!', 'success', Date.now()],
        'error': ['Unable to add the entry!' + error, 'error', Date.now()],
    }
    return adder[status]
}

export const editorLogger = (props) => {
    const status = props[0]
    const error = props[1] ? ' : [' + props[1] + ']' : ''

    const editor = {
        'success': ['Ths entry has been edited successfully!', 'success', Date.now()],
        'error': ['Unable to edit the entry!' + error, 'error', Date.now()],
    }
    return editor[status]
}

export const deleterLogger = (props) => {
    const status = props[0]
    const error = props[1] ? ' : [' + props[1] + ']' : ''

    const deleter = {
        'success': ['The entry has been permanently deleted!', 'warning', Date.now()],
        'error': ['Unable to delete the entry!' + error, 'error', Date.now()],
    }
    return deleter[status]
}