import Grid from '@material-ui/core/Grid'

import { NumFormatInput } from '../../components/NumberFormatCustom'

import { CustomField } from './CustomField'

export const EditorP2 = (props) => {
    const {
        id,
        entry_date,
        suppliers_name,
        type_of_assessable_income,
        amount_paid,
        vat_percentage,
        wht_percentage,
        book_no,
        order_no,
        //vat_amount,
        //wht_deducted,
        //amount_transfer,
        single_entry,
        entry_remark,
    } = props.wht ? props.wht : ''

    const total = Number(amount_paid)
    const vat = Number(vat_percentage) / 100
    const wht = Number(wht_percentage) / 100

    const pre_vat_amount = total / (1 + vat)
    const pre_wht_amount = total - (pre_vat_amount * wht)
    const vat_amount = total - pre_vat_amount
    const wht_amount = total - pre_wht_amount

    const amount_transfer = total - wht_amount

    const editable = props.editable

    return (<Grid container spacing={3}>

        <Grid item xs={12} sm={6}><CustomField label="จำนวนเงินก่อน VAT" helperText="ตัวเลขในหนังสือรับรอง ฯ" value={pre_vat_amount} disabled customs={{ type: 'freestyle', id: id, formatter: NumFormatInput }} editable={editable} /></Grid>
        <Grid item xs={12} sm={6}><CustomField label="ภาษีมูลค่าเพิ่ม (VAT)" value={vat_amount} disabled customs={{ type: 'freestyle', id: id, formatter: NumFormatInput }} editable={editable} /></Grid>
        <Grid item xs={12} sm={6}><CustomField label="ภาษีที่หักและนำส่ง (WHT)" helperText="ตัวเลขในหนังสือรับรอง ฯ" value={wht_amount} disabled customs={{ type: 'freestyle', id: id, formatter: NumFormatInput }} editable={editable} /></Grid>
        <Grid item xs={12} sm={6}><CustomField label="จำนวนเงินที่ต้องโอน" helperText="จำนวนเงินหลังจาก หัก ณ ที่จ่าย" value={amount_transfer} disabled customs={{ type: 'freestyle', id: id, formatter: NumFormatInput }} editable={editable} /></Grid>

    </Grid>
    )
}