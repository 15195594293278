import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

export const TabPanel = (props) => {
    const { children, currentTab, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={currentTab !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {currentTab === index && (
                <Grid>{children}</Grid>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    currentTab: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}