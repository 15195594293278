import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { currencySelection, paidBySelection, signatureSelection, companyStampSelection, descSelection } from '../../components/options/Options'
import { NumFormatInput } from '../../components/NumberFormatCustom'

import { CustomField } from './CustomField'

import { useStyles } from '../../styles/receipts/Styles.Textfield'

export const EditorP2 = (props) => {
    const classes = useStyles()

    const {
        id,
        customer_name,
        flex_desc01,
        flex_desc02,
        flex_desc03,
        flex_desc04,
        flex_desc05,
        flex_desc06,
        flex_desc07,
        flex_desc08,
        flex_desc09,
        flex_desc10,
        flex_amount01,
        flex_amount02,
        flex_amount03,
        flex_amount04,
        flex_amount05,
        flex_amount06,
        flex_amount07,
        flex_amount08,
        flex_amount09,
        flex_amount10,
        flex_amount_paid,
        flex_currency,
        flex_method_of_payment,
        flex_signature,
        flex_company_stamp,
    } = props.receipts ? props.receipts : ''

    const subtotal = [flex_amount01, flex_amount02, flex_amount03, flex_amount04, flex_amount05, flex_amount06, flex_amount07, flex_amount08, flex_amount09, flex_amount10].map(Number).reduce((a, b) => a + b, 0)
    const finalamount = subtotal - Number(flex_amount_paid)

    const editable = props.editable

    return (<Grid container spacing={3}>

        <Grid item xs={12} sm={6}><CustomField label="Customer Name" disabled customs={{ type: 'freestyle', id: id, propval: customer_name, }} editable={editable} /></Grid>

        <Grid item xs={12} sm={4}><CustomField label="Method of Payment" customs={{ type: 'selector', id: id, propkey: 'flex_method_of_payment', propval: flex_method_of_payment, options: paidBySelection, }} editable={editable} /></Grid>
        <Grid item xs={12} sm={2}><CustomField label="Currency" customs={{ type: 'selector', id: id, propkey: 'flex_currency', propval: flex_currency, options: currencySelection, }} editable={editable} /></Grid>

        {/* Description & Amount */}
        <Grid container item className={classes.tabular}>
            <Grid container item xs={12} sm={12}>
                <Grid item xs={8} sm={8}><Typography className={classes.header}>Description</Typography></Grid>
                <Grid item xs={4} sm={4}><Typography className={classes.header}>Amount</Typography></Grid>
            </Grid>

            <Grid container item xs={12} sm={12}>
                <Grid item xs={8} sm={8}><CustomField customs={{ type: 'selector', id: id, propkey: 'flex_desc01', propval: flex_desc01, options: descSelection, }} editable={editable} /></Grid>
                <Grid item xs={4} sm={4}><CustomField customs={{ type: 'freestyle', id: id, propkey: 'flex_amount01', propval: flex_amount01, formatter: NumFormatInput }} editable={editable} /></Grid>
            </Grid>

            <Grid container item xs={12} sm={12}>
                <Grid item xs={8} sm={8}><CustomField customs={{ type: 'selector', id: id, propkey: 'flex_desc02', propval: flex_desc02, options: descSelection, }} editable={editable} /></Grid>
                <Grid item xs={4} sm={4}><CustomField customs={{ type: 'freestyle', id: id, propkey: 'flex_amount02', propval: flex_amount02, formatter: NumFormatInput }} editable={editable} /></Grid>
            </Grid>

            <Grid container item xs={12} sm={12}>
                <Grid item xs={8} sm={8}><CustomField customs={{ type: 'selector', id: id, propkey: 'flex_desc03', propval: flex_desc03, options: descSelection, }} editable={editable} /></Grid>
                <Grid item xs={4} sm={4}><CustomField customs={{ type: 'freestyle', id: id, propkey: 'flex_amount03', propval: flex_amount03, formatter: NumFormatInput }} editable={editable} /></Grid>
            </Grid>

            <Grid container item xs={12} sm={12}>
                <Grid item xs={8} sm={8}><CustomField customs={{ type: 'selector', id: id, propkey: 'flex_desc04', propval: flex_desc04, options: descSelection, }} editable={editable} /></Grid>
                <Grid item xs={4} sm={4}><CustomField customs={{ type: 'freestyle', id: id, propkey: 'flex_amount04', propval: flex_amount04, formatter: NumFormatInput }} editable={editable} /></Grid>
            </Grid>

            <Grid container item xs={12} sm={12}>
                <Grid item xs={8} sm={8}><CustomField customs={{ type: 'selector', id: id, propkey: 'flex_desc05', propval: flex_desc05, options: descSelection, }} editable={editable} /></Grid>
                <Grid item xs={4} sm={4}><CustomField customs={{ type: 'freestyle', id: id, propkey: 'flex_amount05', propval: flex_amount05, formatter: NumFormatInput }} editable={editable} /></Grid>
            </Grid>

            <Grid container item xs={12} sm={12}>
                <Grid item xs={8} sm={8}><CustomField customs={{ type: 'selector', id: id, propkey: 'flex_desc06', propval: flex_desc06, options: descSelection, }} editable={editable} /></Grid>
                <Grid item xs={4} sm={4}><CustomField customs={{ type: 'freestyle', id: id, propkey: 'flex_amount06', propval: flex_amount06, formatter: NumFormatInput }} editable={editable} /></Grid>
            </Grid>

            <Grid container item xs={12} sm={12}>
                <Grid item xs={8} sm={8}><CustomField customs={{ type: 'selector', id: id, propkey: 'flex_desc07', propval: flex_desc07, options: descSelection, }} editable={editable} /></Grid>
                <Grid item xs={4} sm={4}><CustomField customs={{ type: 'freestyle', id: id, propkey: 'flex_amount07', propval: flex_amount07, formatter: NumFormatInput }} editable={editable} /></Grid>
            </Grid>

            <Grid container item xs={12} sm={12}>
                <Grid item xs={8} sm={8}><CustomField customs={{ type: 'selector', id: id, propkey: 'flex_desc08', propval: flex_desc08, options: descSelection, }} editable={editable} /></Grid>
                <Grid item xs={4} sm={4}><CustomField customs={{ type: 'freestyle', id: id, propkey: 'flex_amount08', propval: flex_amount08, formatter: NumFormatInput }} editable={editable} /></Grid>
            </Grid>

            <Grid container item xs={12} sm={12}>
                <Grid item xs={8} sm={8}><CustomField customs={{ type: 'selector', id: id, propkey: 'flex_desc09', propval: flex_desc09, options: descSelection, }} editable={editable} /></Grid>
                <Grid item xs={4} sm={4}><CustomField customs={{ type: 'freestyle', id: id, propkey: 'flex_amount09', propval: flex_amount09, formatter: NumFormatInput }} editable={editable} /></Grid>
            </Grid>

            <Grid container item xs={12} sm={12}>
                <Grid item xs={8} sm={8}><CustomField customs={{ type: 'selector', id: id, propkey: 'flex_desc10', propval: flex_desc10, options: descSelection, }} editable={editable} /></Grid>
                <Grid item xs={4} sm={4}><CustomField customs={{ type: 'freestyle', id: id, propkey: 'flex_amount10', propval: flex_amount10, formatter: NumFormatInput }} editable={editable} /></Grid>
            </Grid></Grid>

        <Grid container item xs={12} sm={12} className={classes.tabularbtm}>
            <Grid item xs={12} sm={4}><CustomField label="Total Amount Due" disabled value={subtotal} customs={{ type: 'freestyle', id: id, formatter: NumFormatInput }} editable={editable} /></Grid>
        </Grid>

        <Grid container item xs={12} sm={12} className={classes.tabularbtm}>
            <Grid item xs={12} sm={4}><CustomField label="Amount Paid" customs={{ type: 'freestyle', id: id, propkey: 'flex_amount_paid', propval: flex_amount_paid, formatter: NumFormatInput }} editable={editable} /></Grid>
        </Grid>

        <Grid container item xs={12} sm={12} className={classes.tabularbtm}>
            <Grid item xs={12} sm={4}><CustomField label="Balance Due" disabled value={finalamount} customs={{ type: 'freestyle', id: id, formatter: NumFormatInput }} editable={editable} /></Grid>
        </Grid>
        {/* Payment Information */}

        <Grid item xs={12} sm={6}><CustomField label="Authorized Signature" customs={{ type: 'selector', id: id, propkey: 'flex_signature', propval: flex_signature, options: signatureSelection, }} editable={editable} /></Grid>
        <Grid item xs={12} sm={6}><CustomField label="Company Stamp" customs={{ type: 'selector', id: id, propkey: 'flex_company_stamp', propval: flex_company_stamp, options: companyStampSelection, }} editable={editable} /></Grid>

    </Grid>
    )
}