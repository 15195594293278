import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { editorClosed, deleterOpened } from '../app/appSlice'
import { selectInvoicesById } from './invoicesSlice'

import { SaveButton, CancelButton, PrintButton, SendButton } from '../../components/FunctionalButtons'

import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Switch from '@material-ui/core/Switch'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'

import { TabPanel } from '../../components/TabPanel'
import { EditorP1 } from './EditorP1'
import { EditorP2 } from './EditorP2'
import { EditorP3 } from './EditorP3'

export const Editor = () => {
    const app = useSelector(state => state.app.editor)
    const invoicesId = app

    const invoices = useSelector(state => selectInvoicesById(state, invoicesId))
    const [currentTab, setCurrentTab] = useState(0)

    const [editable, setEditable] = useState(false)
    const [addRequestStatus, setAddRequestStatus] = useState('idle')

    const addGlobalCurrency = currentTab === 2 ? '' : '&currency=global'

    const dispatch = useDispatch()

    const canSave = [invoicesId].every(Boolean) && invoicesId != 0 && addRequestStatus === 'idle' && editable === true

    const handleChange = (event, newValue) => {
        setCurrentTab(newValue)
    }

    const handleEditable = (event, newValue) => { setEditable(newValue) }

    return (
        <Dialog
            open={Boolean(invoicesId)}
            onClose={() => dispatch(editorClosed())}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth="md"
            scroll="body"
        >
            <Tabs
                value={currentTab}
                onChange={handleChange}
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary"
                aria-label="icon label tabs example"
            >
                <Tab icon={<AccountCircleIcon />} label="Invoices" />
                <Tab icon={<MonetizationOnIcon />} label="Intl Currency" />
                <Tab icon={<MonetizationOnIcon />} label="THB" />
            </Tabs>

            <DialogTitle disableTypography>
                <Grid container>
                    <Grid item xs={false} sm={2} />
                    <Grid item xs={10} sm={8}>
                        <Typography variant="h4" align="center">Invoices</Typography>
                    </Grid>
                    <Grid container item xs={2} sm={2} justify="flex-end">
                        <Switch
                            checked={editable}
                            onChange={handleEditable}
                            color="primary"
                        />
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                {/* <DialogContentText>
                    To edit this existing entry, please fill all details including date, category, descriptions, and either income or expense.
                </DialogContentText> */}
                <form noValidate autoComplete="new-password" style={{ padding: 6 }}>
                    <TabPanel currentTab={currentTab} index={0}>
                        <EditorP1 invoices={invoices} currentTab={currentTab} editable={editable.toString()} />
                    </TabPanel>
                    <TabPanel currentTab={currentTab} index={1}>
                        <EditorP2 invoices={invoices} currentTab={currentTab} editable={editable.toString()} />
                    </TabPanel>
                    <TabPanel currentTab={currentTab} index={2}>
                        <EditorP3 invoices={invoices} currentTab={currentTab} editable={editable.toString()} />
                    </TabPanel>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <DialogActions>
                                <Button onClick={() => dispatch(deleterOpened({ 'deleter': invoicesId }))} disabled={!canSave} >
                                    Delete
                                </Button>
                                <Button disabled={!canSave} >
                                    Copy
                                </Button>
                                <CancelButton canParentSave={canSave} />
                                <SaveButton canParentSave={canSave} />
                                <PrintButton href={"https://cfa.siam.luxe/invoices/print/?table=Invoices&key=" + invoicesId + addGlobalCurrency} canParentSave={canSave} />
                                <SendButton href={"https://www.siam.luxe/finance-erp/?table=Invoices&key=" + invoicesId + addGlobalCurrency} canParentSave={canSave} />
                            </DialogActions>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </Dialog >
    )
}