import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { editorClosed, deleterOpened } from '../app/appSlice'
import { selectBkById } from './bkSlice'

import { SaveButton, CancelButton } from '../../components/FunctionalButtons'

import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Switch from '@material-ui/core/Switch'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

import { EditorP1 } from './EditorP1'

export const Editor = () => {
    const app = useSelector(state => state.app.editor)
    const bkId = app

    const bk = useSelector(state => selectBkById(state, bkId))

    const [editable, setEditable] = useState(false)
    const [addRequestStatus, setAddRequestStatus] = useState('idle')

    const dispatch = useDispatch()

    const canSave = [bkId].every(Boolean) && bkId != 0 && addRequestStatus === 'idle' && editable === true

    const handleEditable = (event, newValue) => { setEditable(newValue) }

    return (
        <Dialog
            open={Boolean(bkId)}
            onClose={() => dispatch(editorClosed())}
            aria-labelledby="form-dialog-title"
            //fullWidth={true}
            //maxWidth="md"
            scroll="body"
        >

            <DialogTitle disableTypography>
                <Grid container>
                    <Grid item xs={false} sm={2} />
                    <Grid item xs={10} sm={8}>
                        <Typography variant="h4" align="center">Bk</Typography>
                    </Grid>
                    <Grid container item xs={2} sm={2} justify="flex-end">
                        <Switch
                            checked={editable}
                            onChange={handleEditable}
                            color="primary"
                        />
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent>
                {/* <DialogContentText>
                    To edit this existing entry, please fill all details including date, category, descriptions, and either income or expense.
                </DialogContentText> */}
                <form noValidate autoComplete="new-password" style={{ padding: 6 }}>
                    <EditorP1 bk={bk} editable={editable.toString()} />
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <DialogActions>
                                <Button onClick={() => dispatch(deleterOpened({ 'deleter': bkId }))} disabled={!canSave} >
                                    Delete
                                </Button>
                                <CancelButton canParentSave={canSave}>Save</CancelButton>
                                <SaveButton canParentSave={canSave}>Save</SaveButton>
                            </DialogActions>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </Dialog>
    )
}