import { createSlice, createAsyncThunk, createSelector, createEntityAdapter } from '@reduxjs/toolkit'
import { client } from '../../api/client'

const summaryAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.entry_date.localeCompare(b.entry_date),
})

const initialState = summaryAdapter.getInitialState({
  status: 'idle',
  error: null,
  logger: '',
})

export const fetchSummary = createAsyncThunk(
  'summary/fetchSummary',
  async (props, thunkAPI) => {
    if (props.useOption != '') {
      const options = '/' + props.options
      const response = await client.get(
        '../../api/summary' + options,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )

      return response.summary
    }
    const response = await client.get('../../api/summary')
    return response.summary
  }
)

export const addNewSummary = createAsyncThunk(
  'summary/addNewSummary',
  async (initialData, { rejectWithValue }) => {
    try {
      const response = await client.post(
        '../../api/summary/add',
        {
          summary: initialData
        },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      return response.summary
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const saveSummary = createAsyncThunk(
  'summary/saveSummary',
  async (initialData, { rejectWithValue }) => {
    try {
      const response = await client.post(
        '../../api/summary/save',
        {
          summary: initialData
        },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      return response.summary
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const deleteSummary = createAsyncThunk(
  'summary/deleteSummary',
  async (initialData, { rejectWithValue }) => {
    try {
      const response = await client.post(
        '../../api/summary/delete',
        {
          summary: initialData
        },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      return response.summary
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

const summarySlice = createSlice({
  name: 'summary',
  initialState,
  reducers: {
    refetch: state => {
      state.status = 'idle'
    },
  },
  extraReducers: {
    [fetchSummary.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchSummary.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      // summaryAdapter.upsertMany(state, action.payload)
      summaryAdapter.setAll(state, action.payload)
    },
    [fetchSummary.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.payload
      console.log('%cError: Unable to fetch data from the database!', 'color:red')
    },
    [addNewSummary.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      summaryAdapter.addOne(state, action.payload)
    },
    [addNewSummary.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = 'Unable to add a new entry! (' + action.error.message + ')'
    },
    [saveSummary.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      summaryAdapter.upsertOne(state, action.payload)
    },
    [saveSummary.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = 'Unable to edit this selected entry! (' + action.error.message + ')'
    },
    [deleteSummary.fulfilled]: (state, action) => {
      const { id } = action.payload
      state.status = 'succeeded'
      summaryAdapter.removeOne(state, id)
    },
    [deleteSummary.rejected]: (state, action) => {
      const { id } = action.payload
      state.status = 'failed'
      state.error = 'Unable to delete this selected entry! (' + action.error.message + ' | id: ' + id + ')'
    },
  }
})

export const { refetch } = summarySlice.actions

export default summarySlice.reducer

export const {
  selectAll: selectAllSummary,
  selectById: selectSummaryById,
  selectIds: selectSummaryIds,
} = summaryAdapter.getSelectors(state => state.summary)