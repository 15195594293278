import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'

import SignIn from './features/auth/SignIn'
import Register from './features/auth/Register'
import Dashboard from './features/auth/Dashboard'
import Snacks from './components/Snacks'

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

import 'fontsource-open-sans'

import './App.css'

const primaryColor = '#1ec8bb'

const theme = createMuiTheme({
  /* changing all default material ui components // might not necessary here in App.js */
  props: {
    MuiButton: {
      color: 'primary',
    },
  },

  overrides: {
    MuiCssBaseline: {
      '@global': {
        // TextField (Border)
        '.MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
          borderColor: primaryColor,
        },
        // TextField:disabled (Border and Others)
        '.MuiFormControl-root .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
          borderColor: primaryColor,
        },
        '.MuiInputBase-root .MuiInputBase-input.Mui-disabled': {
          color: '#00024c',
          background: '#f3f3f3b3'
        },
        'label.MuiFormLabel-root.Mui-disabled': {
          color: 'rgba(0, 0, 0, 0.54)',
        },
        // Scrollbar
        'main::-webkit-scrollbar, list::-webkit-scrollbar, .MuiDialog-scrollBody::-webkit-scrollbar': {
          width: '0.4em'
        },
        'main::-webkit-scrollbar-track, list::-webkit-scrollbar-track, .MuiDialog-scrollBody::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        'main::-webkit-scrollbar-thumb, list::-webkit-scrollbar-thumb, .MuiDialog-scrollBody::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,0,0,.1)',
          outline: '1px solid slategrey'
        },
        // Scrollbar for Autoselect
        '.MuiAutocomplete-popper *::-webkit-scrollbar': {
          width: '0'
        },
        '.MuiAutocomplete-popper *::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '.MuiAutocomplete-popper *::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,0,0,.1)',
          outline: '1px solid slategrey'
        },
      },
    },
  },

  typography: {
    // In Chinese and Japanese the characters are usually larger,
    // so a smaller fontsize may be appropriate.
    fontSize: 14,
    allVariants: {
      //color: '#00024c' // it will change the color of placeholder, which is certainly unintentional
    },
    h6: {
      color: '#fff',
      fontWeight: 'bold',
    }
  },

  /* changing color here in App.js may be enough to change all child components */
  palette: {
    primary: {
      light: '#757ce8',
      main: primaryColor,
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
      //contrastText: '#00024c', //black ew
    },
  },
})

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Switch>
          <Route exact path="/signin" component={SignIn} />
          <Route exact path="/register" component={Register} />
          <Dashboard />
          <Redirect to="/signin" />
        </Switch>
        <Snacks />
      </Router>
    </ThemeProvider>
  )
}

export default App