import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Link as LinkRouter, useLocation } from 'react-router-dom'

import { selectAllTourcode, fetchTourcode } from './tourcodeSlice'
//import { tourcodeType } from '../../components/SelectOptions'
import { editorOpened, deleterOpened } from '../app/appSlice'

import { shorternCurrentStatus } from '../../components/options/Options'

import { DataGrid } from '@material-ui/data-grid'
import Link from '@material-ui/core/Link'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'

import useMediaQuery from '@material-ui/core/useMediaQuery'

import { TCFormatDisplay } from '../../components/NumberFormatCustom'
import { HorizontalLoadingOverlay } from '../../components/HorizontalLoadingOverlay'

import { useStyles } from '../../styles/tourcode/Styles.DataList'

export const TourcodePage = (props) => {
    const classes = useStyles()
    const isMobile = useMediaQuery('(max-width:1024px)')

    const location = useLocation()
    const search = location.search.replace("?s=", "")

    useEffect(() => {
        if (typeof search === "string" && search.trim().length === 0) {
            console.log("search is empty.")
        } else {
            console.log(search === "booked" ? "correct: booked" : search)
        }
    }, [])

    const dispatch = useDispatch()
    const tourcode = useSelector(selectAllTourcode)

    const tourcodeStatus = useSelector(state => state.tourcode.status)
    const error = useSelector(state => state.tourcode.error)

    const fetch = props.fetch

    useEffect(() => {
        if (typeof search === "string" && search.trim().length === 0) {
            if (tourcodeStatus === 'idle') {
                dispatch(fetchTourcode({
                    useOption: true,
                    options: fetch,
                    //start_date: '',
                    //end_date: '',
                }))
            }
        } else {
            if (tourcodeStatus === 'idle') {
                dispatch(fetchTourcode({
                    useOption: true,
                    options: search,
                    //start_date: '',
                    //end_date: '',
                }))
            }
        }
     
    }, [tourcodeStatus, dispatch])

let content
let columns

if (isMobile) {
    columns = [
        {
            field: 'virtual_id',
            headerName: '#',
            width: 50,
            disableColumnMenu: true,
            sortable: false,
            valueGetter: (params) => (
                params.rowIndex + 1
            )
        },
        {
            field: 'entry_date',
            headerName: 'Date',
            width: 120,
            type: 'date',
            valueFormatter: (params) => (
                new Date(params.getValue('entry_date')).toLocaleString("en-US", { year: 'numeric', month: 'short', day: 'numeric' })
            )
        },
        {
            field: 'tour_code',
            headerName: 'Tour Code',
            width: 100,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => (
                TCFormatDisplay(params.getValue('tour_code'))
            )
        },
        {
            field: 'entry_desc',
            headerName: 'Description',
            width: 350,
            disableColumnMenu: true,
            sortable: false,
        },
        {
            field: 'entry_customer',
            headerName: 'Customer',
            width: 250,
            disableColumnMenu: true,
            sortable: false
        },
        {
            field: 'additional_note',
            headerName: 'Notes',
            width: 120,
            disableColumnMenu: true,
            sortable: false,
        },
        {
            field: 'current_status',
            headerName: '✓',
            width: 50,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => (
                shorternCurrentStatus[params.getValue('current_status')]
            )
        },
        {
            field: 'url_tc',
            headerName: 'TC',
            width: 50,
            disableColumnMenu: true,
            sortable: false,
            selectable: false,
            renderCell: (params) => (
                params.row.url_tc ? <Link href={params.row.url_tc} target="_blank" rel="noreferrer"><ExitToAppIcon /></Link> : ''
            )
        },
    ]
} else {
    columns = [
        {
            field: 'virtual_id',
            headerName: '#',
            width: 50,
            disableColumnMenu: true,
            sortable: false,
            valueGetter: (params) => (
                params.rowIndex + 1
            )
        },
        {
            field: 'entry_date',
            headerName: 'Date',
            width: 120,
            type: 'date',
            valueFormatter: (params) => (
                new Date(params.getValue('entry_date')).toLocaleString("en-US", { year: 'numeric', month: 'short', day: 'numeric' })
            )
        },
        {
            field: 'tour_code',
            headerName: 'Tour Code',
            width: 120,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => (
                TCFormatDisplay(params.getValue('tour_code'))
            )
        },
        {
            field: 'entry_desc',
            headerName: 'Description',
            flex: 4,
            disableColumnMenu: true,
            sortable: false,
        },
        {
            field: 'entry_customer',
            headerName: 'Customer',
            flex: 3,
            disableColumnMenu: true,
            sortable: false
        },
        {
            field: 'additional_note',
            headerName: 'Notes',
            width: 120,
            disableColumnMenu: true,
            sortable: false,
        },
        {
            field: 'current_status',
            headerName: '✓',
            width: 50,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => (
                shorternCurrentStatus[params.getValue('current_status')]
            )
        },
        {
            field: 'url_tc',
            headerName: 'TC',
            width: 50,
            disableColumnMenu: true,
            sortable: false,
            selectable: false,
            renderCell: (params) => (
                params.row.url_tc ? <Link href={params.row.url_tc} target="_blank" rel="noreferrer"><ExitToAppIcon /></Link> : ''
            )
        },
    ]
}

const rows = tourcode

content = <div className={classes.root} style={{ height: 400, width: '100%' }}>
    <DataGrid
        autoHeight
        sortingOrder={['asc', 'desc']}
        rows={rows}
        columns={columns}
        pageSize={100}
        density="compact"
        rowHeight={48}
        disableMultipleSelection={true}
        disableSelectionOnClick={true}
        hideFooterSelectedRowCount={true}
        onRowClick={(params) => dispatch(editorOpened({ 'editor': params.row.id }))}
        components={{
            LoadingOverlay: HorizontalLoadingOverlay,
        }}
        loading={Boolean(tourcodeStatus === 'loading')}
    />
</div>

return (
    <>
        {content}
    </>
)
}