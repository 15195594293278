import Grid from '@material-ui/core/Grid'

import { CustomField } from './CustomField'

export const EditorP3 = (props) => {
    const {
        id,
        house_no,
        moo,
        soi,
        road,
        sub_district,
        district,
        province,
        post_office,
        village,
        room_no,
        floor_no,
    } = props.suppliers ? props.suppliers : ''

    const editable = props.editable

    return (<Grid container spacing={3}>

        <Grid item xs={12} sm={6}><CustomField label="เลขที่" customs={{ type: 'freestyle', id: id, propkey: 'house_no', propval: house_no, }} editable={editable} /></Grid>
        <Grid item xs={12} sm={6}><CustomField label="หมู่ที่" customs={{ type: 'freestyle', id: id, propkey: 'moo', propval: moo, }} editable={editable} /></Grid>
        <Grid item xs={12} sm={6}><CustomField label="ซอย" customs={{ type: 'freestyle', id: id, propkey: 'soi', propval: soi, }} editable={editable} /></Grid>
        <Grid item xs={12} sm={6}><CustomField label="ถนน" customs={{ type: 'freestyle', id: id, propkey: 'road', propval: road, }} editable={editable} /></Grid>
        <Grid item xs={12} sm={6}><CustomField label="แขวง/ตำบล" customs={{ type: 'freestyle', id: id, propkey: 'sub_district', propval: sub_district, }} editable={editable} /></Grid>
        <Grid item xs={12} sm={6}><CustomField label="เขต/อำเภอ" customs={{ type: 'freestyle', id: id, propkey: 'district', propval: district, }} editable={editable} /></Grid>
        <Grid item xs={12} sm={6}><CustomField label="จังหวัด" customs={{ type: 'freestyle', id: id, propkey: 'province', propval: province, }} editable={editable} /></Grid>
        <Grid item xs={12} sm={6}><CustomField label="รหัสไปรษณีย์" customs={{ type: 'freestyle', id: id, propkey: 'post_office', propval: post_office, }} editable={editable} /></Grid>
        <Grid item xs={12} sm={6}><CustomField label="หมู่บ้าน" customs={{ type: 'freestyle', id: id, propkey: 'village', propval: village, }} editable={editable} /></Grid>
        <Grid item xs={12} sm={6}><CustomField label="ห้องที่" customs={{ type: 'freestyle', id: id, propkey: 'room_no', propval: room_no, }} editable={editable} /></Grid>
        <Grid item xs={12} sm={6}><CustomField label="ชั้นที่" customs={{ type: 'freestyle', id: id, propkey: 'floor_no', propval: floor_no, }} editable={editable} /></Grid>

    </Grid>
    )
}