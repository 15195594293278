import { createSlice, createAsyncThunk, createSelector, createEntityAdapter } from '@reduxjs/toolkit'
import { client } from '../../api/client'

const whtAdapter = createEntityAdapter({
  //sortComparer: (a, b) => b.entry_date.localeCompare(a.entry_date),
})

const initialState = whtAdapter.getInitialState({
  status: 'idle',
  error: null,
  logger: '',
})

export const fetchWHT = createAsyncThunk(
  'wht/fetchWHT',
  async (props, thunkAPI) => {
    if (props.useOption != '') {
      const options = '/' + props.options
      const response = await client.get(
        '../../api/wht' + options,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )

      return response.wht
    }
    const response = await client.get('../../api/wht')
    return response.wht
  }
)

export const addNewWHT = createAsyncThunk(
  'wht/addNewWHT',
  async (initialData, { rejectWithValue }) => {
    try {
      const response = await client.post(
        '../../api/wht/add',
        {
          wht: initialData
        },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      return response.wht
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const saveWHT = createAsyncThunk(
  'wht/saveWHT',
  async (initialData, { rejectWithValue }) => {
    try {
      const response = await client.post(
        '../../api/wht/save',
        {
          wht: initialData
        },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      return response.wht
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const deleteWHT = createAsyncThunk(
  'wht/deleteWHT',
  async (initialData, { rejectWithValue }) => {
    try {
      const response = await client.post(
        '../../api/wht/delete',
        {
          wht: initialData
        },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      return response.wht
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

const whtSlice = createSlice({
  name: 'wht',
  initialState,
  reducers: {
    refetch: state => {
      state.status = 'idle'
    },
  },
  extraReducers: {
    [fetchWHT.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchWHT.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      // whtAdapter.upsertMany(state, action.payload)
      whtAdapter.setAll(state, action.payload)
    },
    [fetchWHT.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.payload
      console.log('%cError: Unable to fetch data from the database!', 'color:red')
    },
    [addNewWHT.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      whtAdapter.addOne(state, action.payload)
    },
    [addNewWHT.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = 'Unable to add a new entry! (' + action.error.message + ')'
    },
    [saveWHT.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      whtAdapter.upsertOne(state, action.payload)
    },
    [saveWHT.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = 'Unable to edit this selected entry! (' + action.error.message + ')'
    },
    [deleteWHT.fulfilled]: (state, action) => {
      const { id } = action.payload
      state.status = 'succeeded'
      whtAdapter.removeOne(state, id)
    },
    [deleteWHT.rejected]: (state, action) => {
      const { id } = action.payload
      state.status = 'failed'
      state.error = 'Unable to delete this selected entry! (' + action.error.message + ' | id: ' + id + ')'
    },
  }
})

export const { refetch } = whtSlice.actions

export default whtSlice.reducer

export const {
  selectAll: selectAllWHT,
  selectById: selectWHTById,
  selectIds: selectWHTIds,
  //} = whtAdapter.getSelectors(state => state.wht)
} = whtAdapter.getSelectors(state => state.wht)