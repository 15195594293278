import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { selectAllSuppliers, fetchSuppliers } from './suppliersSlice'
//import { suppliersType } from '../../components/SelectOptions'
import { editorOpened, deleterOpened } from '../app/appSlice'

import { DataGrid } from '@material-ui/data-grid'

import useMediaQuery from '@material-ui/core/useMediaQuery'

import { TaxFormatDisplay } from '../../components/NumberFormatCustom'
import { HorizontalLoadingOverlay } from '../../components/HorizontalLoadingOverlay'

import { useStyles } from '../../styles/suppliers/Styles.DataList'

export const SuppliersPage = (props) => {
    const classes = useStyles()
    const isMobile = useMediaQuery('(max-width:1024px)')

    const dispatch = useDispatch()
    const suppliers = useSelector(selectAllSuppliers)

    const suppliersStatus = useSelector(state => state.suppliers.status)
    const error = useSelector(state => state.suppliers.error)

    const fetch = props.fetch

    useEffect(() => {
        if (suppliersStatus === 'idle') {
            dispatch(fetchSuppliers({
                useOption: true,
                options: fetch,
                //start_date: '',
                //end_date: '',
            }))
        }
    }, [suppliersStatus, dispatch])

    let content
    let columns

    if (isMobile) {
        columns = [
            {
                field: 'virtual_id',
                headerName: '#',
                width: 50,
                disableColumnMenu: true,
                sortable: false,
                valueGetter: (params) => (
                    params.rowIndex + 1
                )
            },
            {
                field: 'primary_name',
                headerName: 'Name',
                width: 300,
                disableColumnMenu: true,
                sortable: false,
            },
            {
                field: 'fullname',
                headerName: 'Full Name',
                width: 300,
                disableColumnMenu: true,
                sortable: false,
                renderCell: (params) => (
                    [params.row.title, params.row.firstname, params.row.lastname].join(' ')
                )
            },
            {
                field: 'taxid',
                headerName: 'Tax ID',
                type: 'number',
                width: 170,
                disableColumnMenu: true,
                sortable: false,
                renderCell: (params) => (
                    TaxFormatDisplay(params.getValue('taxid'))
                )
            },
            {
                field: 'wht_type',
                headerName: 'WHT Type',
                width: 100,
                disableColumnMenu: true,
                sortable: false,
            },
        ]
    } else {
        columns = [
            {
                field: 'virtual_id',
                headerName: '#',
                width: 50,
                disableColumnMenu: true,
                sortable: false,
                valueGetter: (params) => (
                    params.rowIndex + 1
                )
            },
            {
                field: 'primary_name',
                headerName: 'Name',
                flex: 3,
                disableColumnMenu: true,
                sortable: false,
            },
            {
                field: 'fullname',
                headerName: 'Full Name',
                flex: 4,
                disableColumnMenu: true,
                sortable: false,
                renderCell: (params) => (
                    [params.row.title, params.row.firstname, params.row.lastname].join(' ')
                )
            },
            {
                field: 'taxid',
                headerName: 'Tax ID',
                type: 'number',
                width: 170,
                disableColumnMenu: true,
                sortable: false,
                renderCell: (params) => (
                    TaxFormatDisplay(params.getValue('taxid'))
                )
            },
            {
                field: 'wht_type',
                headerName: 'WHT Type',
                width: 100,
                disableColumnMenu: true,
                sortable: false,
            },
        ]
    }

    const rows = suppliers

    content = <div className={classes.root} style={{ height: 400, width: '100%' }}>

        <DataGrid
            autoHeight
            sortingOrder={['asc', 'desc']}
            rows={rows}
            columns={columns}
            pageSize={100}
            density="compact"
            rowHeight={48}
            disableMultipleSelection={true}
            hideFooterSelectedRowCount={true}
            onRowClick={(params) => dispatch(editorOpened({ 'editor': params.row.id }))}
            components={{
                LoadingOverlay: HorizontalLoadingOverlay,
            }}
            loading={Boolean(suppliersStatus === 'loading')}
        />

    </div>

    return (
        <>
            {content}
        </>
    )
}