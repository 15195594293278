import { createSlice, createAsyncThunk, createSelector, createEntityAdapter } from '@reduxjs/toolkit'
import { client } from '../../api/client'

const invoicesAdapter = createEntityAdapter({
  //sortComparer: (a, b) => b.entry_date.localeCompare(a.entry_date),
})

const initialState = invoicesAdapter.getInitialState({
  status: 'idle',
  error: null,
  logger: '',
})

export const fetchInvoices = createAsyncThunk(
  'invoices/fetchInvoices',
  async (props, thunkAPI) => {
    if (props.useOption != '') {
      const options = '/' + props.options
      const response = await client.get(
        '../../api/invoices' + options,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )

      return response.invoices
    }
    const response = await client.get('../../api/invoices')
    return response.invoices
  }
)

export const addNewInvoices = createAsyncThunk(
  'invoices/addNewInvoices',
  async (initialData, { rejectWithValue }) => {
    try {
      const response = await client.post(
        '../../api/invoices/add',
        {
          invoices: initialData
        },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      return response.invoices
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const saveInvoices = createAsyncThunk(
  'invoices/saveInvoices',
  async (initialData, { rejectWithValue }) => {
    try {
      const response = await client.post(
        '../../api/invoices/save',
        {
          invoices: initialData
        },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      return response.invoices
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const deleteInvoices = createAsyncThunk(
  'invoices/deleteInvoices',
  async (initialData, { rejectWithValue }) => {
    try {
      const response = await client.post(
        '../../api/invoices/delete',
        {
          invoices: initialData
        },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      return response.invoices
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

const invoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    refetch: state => {
      state.status = 'idle'
    },
  },
  extraReducers: {
    [fetchInvoices.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchInvoices.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      // invoicesAdapter.upsertMany(state, action.payload)
      invoicesAdapter.setAll(state, action.payload)
    },
    [fetchInvoices.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.payload
      console.log('%cError: Unable to fetch data from the database!', 'color:red')
    },
    [addNewInvoices.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      invoicesAdapter.addOne(state, action.payload)
    },
    [addNewInvoices.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = 'Unable to add a new entry! (' + action.error.message + ')'
    },
    [saveInvoices.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      invoicesAdapter.upsertOne(state, action.payload)
    },
    [saveInvoices.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = 'Unable to edit this selected entry! (' + action.error.message + ')'
    },
    [deleteInvoices.fulfilled]: (state, action) => {
      const { id } = action.payload
      state.status = 'succeeded'
      invoicesAdapter.removeOne(state, id)
    },
    [deleteInvoices.rejected]: (state, action) => {
      const { id } = action.payload
      state.status = 'failed'
      state.error = 'Unable to delete this selected entry! (' + action.error.message + ' | id: ' + id + ')'
    },
  }
})

export const { refetch } = invoicesSlice.actions

export default invoicesSlice.reducer

export const {
  selectAll: selectAllInvoices,
  selectById: selectInvoicesById,
  selectIds: selectInvoicesIds,
} = invoicesAdapter.getSelectors(state => state.invoices)