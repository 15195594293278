import { useState, useEffect } from 'react'

import { client } from '../../api/client'

import StarIcon from '@material-ui/icons/Star'
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff'
import DoneIcon from '@material-ui/icons/Done'
import CancelScheduleSendIcon from '@material-ui/icons/CancelScheduleSend'

export const OptionsFetcher = (props) => {
    const { slug, filter_by } = props || ''
    const [options, setOptions] = useState([])

    useEffect(() => {
        fetch()
    }, [filter_by])

    const fetch = async (props) => {
        const response = await client.post(
            `../../api/options/${slug}`,
            {
                'filter_by': filter_by
            },
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            },
        )
        setOptions(options => {
            return Object.values(response)
        })
    }

    //console.log(options)
    return options
}

/* Bk */
export const categorySelection = [
    'Sales Revenue',
    'Cost of Sales',
    'Office Supplies',
    'Office Expenses',
    'Equipment',
    'Prepaid Travel Insurance',
    'Withholding Tax Payable',
]
export const entryDescSelection = [
    'รายได้ค่าบริการนำเที่ยว',
    'ค่าน้ำมันรถกรรมการ',
    'ค่าไปรษณีย์',
    'หนังสือรับรอง กรมพัฒนาธุรกิจการค้า',
    'ประกันนักท่องเที่ยว (xxx บาท)',
    'คชจ.จัดการทัวร์ - มัคคุเทศก์ xxx',
    'ยื่น ภ.ง.ด.3',
    'ยื่น ภ.ง.ด.53'
]
export const typeOrganizing = {
    'Sales Revenue': 1,
    'Cost of Sales': 2,
    'Office Supplies': 3,
    'Office Expenses': 3,
    'Equipment': 3,
    'Prepaid Travel Insurance': 4,
    'Withholding Tax Payable': 4,
}

/* Suppliers */
export const titleSelection = [
    'นาย',
    'นาง',
    'นางสาว',
]
export const whtTypeSelection = [
    'ภ.ง.ด.3',
    'ภ.ง.ด.53'
]

/* Tourcode */
export const currentStatusSelection = [
    'InProgress',
    'Potential',
    'Booked',
    'Departed',
    'Cancelled',
]
export const tourCategorySelection = [
    'Inbound',
    'Outbound',
    'PT',
]
export const shorternCurrentStatus = {
    'InProgress': <StarIcon />,
    'Potential': <StarIcon />,
    'Booked': <DoneIcon />,
    'Departed': <FlightTakeoffIcon />,
    'Cancelled': <CancelScheduleSendIcon />,
}

/* Invoices */
export const revisionSelection = [
    'R1',
    'R2',
    'R3',
    'R4',
    'R5',
    'R6',
    'R7',
    'R8',
    'R9',
]
export const termsAndConditionsSelection = [
    'Siam Luxe',
    'Asia Institute',
    'Asia-Pacific Academic',
]

/* Invoices & Receipts */
export const referenceSelection = [
    'Quotation 1',
    'Quotation 2',
    'Quotation 3',
    'Quotation 4',
    'Quotation 5',
    'Quotation 6',
    'Quotation 7',
    'Quotation 8',
    'Quotation 9',
]

/* Receipts */
export const ReceiptNoSelection = (props) => {
    const today = new Date(props)
    const [receipt_gen1, set_receipt_gen1] = useState('')
    const [receipt_gen2, set_receipt_gen2] = useState('')
    const [receipt_gen3, set_receipt_gen3] = useState('')
    const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']

    useEffect(() => {
        if (today) {
            set_receipt_gen1(today.getFullYear().toString().substr(-2) + '/' + months[today.getMonth()] + '-' + ('' + Math.random()).substring(2, 4) + ('' + Math.random()).substring(2, 4))
            set_receipt_gen2(today.getFullYear().toString().substr(-2) + '/' + months[today.getMonth()] + '-' + ('' + Math.random()).substring(2, 4) + ('' + Math.random()).substring(2, 4))
            set_receipt_gen3(today.getFullYear().toString().substr(-2) + '/' + months[today.getMonth()] + '-' + ('' + Math.random()).substring(2, 4) + ('' + Math.random()).substring(2, 4))
        }
    }, [props])

    return [
        receipt_gen1,
        receipt_gen2,
        receipt_gen3,
    ]
}
export const currencySelection = [
    'USD',
    'EUR',
    'JPY',
    'TWD',
    'CNY',
]
export const methodOfPaymentSelection = [
    'KBANK | Siam Luxe',
    'BBL | Siam Luxe',
    'KBANK | JunNy',
    'KBANK | Nutcha',
    'WISE USD | Siam Luxe',
    'ICBC | Yifang',
    'Credit Card',
    'Cash Payment',
    'Mixed or Split Payments',
]
export const paidBySelection = [
    'Bank Transfer',
    'Credit Card',
    'Cash',
    'Cash on Arrival',
    'Mixed or Split Payments',
]
export const signatureSelection = [
    'June',
    'Nutcha',
    'Arunee',
]
export const companyStampSelection = [
    'Siam Luxe',
]
export const descSelection = [
    'Tailor-Made Thailand Tour',
    'Tailor-Made Southeast Asia Tour',
    'Tailor-Made Tour: Bangkok, Chiang Mai, Krabi',
    'Single Room Supplement',
    'FOC for 1 Tour Leader',
    'FOC for 1 Tour Leader (Single Room)',
    'FOC for 1 Tour Leader (Shared Room)',
    'Complimentary for 1 Tour Leader',
    'Complimentary for 1 Tour Leader (Single Room)',
    'Complimentary for 1 Tour Leader (Shared Room)',
    'Flight Tickets',
    '- TG000 BKK-BKK 01JAN2020 XXXX-XXXX hrs',
    'Room Upgrade (Luxury Room, X Nights) at Hotel Name'
]

/* WHT */
export const typeOfAssessableIncomeSelection = [
    'ค่ามัคคุเทศก์',
    'ค่าบัญชี',
    'ขนส่งไม่ประจำทาง',
]

// export const categorySelection = [
//     { label: 'Sales Revenue 🐱', value: 'Sales Revenue', },
//     { label: 'Cost of Sales', value: 'Cost of Sales', },
//     { label: 'Office Suppliers', value: 'Office Supplies', },
//     { label: 'Office Expenses', value: 'Office Expenses', },
//     { label: 'Equipment', value: 'Equipment', },
//     { label: 'Prepaid Travel Insurance', value: 'Prepaid Travel Insurance', },
//     { label: 'Withholding Tax Payable', value: 'Withholding Tax Payable', },
// ]

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
export const countrySelection = [
    'Andorra',
    'United Arab Emirates',
    'Afghanistan',
    'Antigua and Barbuda',
    'Anguilla',
    'Albania',
    'Armenia',
    'Angola',
    'Antarctica',
    'Argentina',
    'American Samoa',
    'Austria',
    'Australia',
    'Aruba',
    'Alland Islands',
    'Azerbaijan',
    'Bosnia and Herzegovina',
    'Barbados',
    'Bangladesh',
    'Belgium',
    'Burkina Faso',
    'Bulgaria',
    'Bahrain',
    'Burundi',
    'Benin',
    'Saint Barthelemy',
    'Bermuda',
    'Brunei Darussalam',
    'Bolivia',
    'Brazil',
    'Bahamas',
    'Bhutan',
    'Bouvet Island',
    'Botswana',
    'Belarus',
    'Belize',
    'Canada',
    'Cocos (Keeling) Islands',
    'Congo, Democratic Republic of the',
    'Central African Republic',
    'Congo, Republic of the',
    'Switzerland',
    "Cote d'Ivoire",
    'Cook Islands',
    'Chile',
    'Cameroon',
    'China',
    'Colombia',
    'Costa Rica',
    'Cuba',
    'Cape Verde',
    'Curacao',
    'Christmas Island',
    'Cyprus',
    'Czech Republic',
    'Germany',
    'Djibouti',
    'Denmark',
    'Dominica',
    'Dominican Republic',
    'Algeria',
    'Ecuador',
    'Estonia',
    'Egypt',
    'Western Sahara',
    'Eritrea',
    'Spain',
    'Ethiopia',
    'Finland',
    'Fiji',
    'Falkland Islands (Malvinas)',
    'Micronesia, Federated States of',
    'Faroe Islands',
    'France',
    'Gabon',
    'United Kingdom',
    'Grenada',
    'Georgia',
    'French Guiana',
    'Guernsey',
    'Ghana',
    'Gibraltar',
    'Greenland',
    'Gambia',
    'Guinea',
    'Guadeloupe',
    'Equatorial Guinea',
    'Greece',
    'South Georgia and the South Sandwich Islands',
    'Guatemala',
    'Guam',
    'Guinea-Bissau',
    'Guyana',
    'Hong Kong',
    'Heard Island and McDonald Islands',
    'Honduras',
    'Croatia',
    'Haiti',
    'Hungary',
    'Indonesia',
    'Ireland',
    'Israel',
    'Isle of Man',
    'India',
    'British Indian Ocean Territory',
    'Iraq',
    'Iran, Islamic Republic of',
    'Iceland',
    'Italy',
    'Jersey',
    'Jamaica',
    'Jordan',
    'Japan',
    'Kenya',
    'Kyrgyzstan',
    'Cambodia',
    'Kiribati',
    'Comoros',
    'Saint Kitts and Nevis',
    "Korea, Democratic People's Republic of",
    'Korea, Republic of',
    'Kuwait',
    'Cayman Islands',
    'Kazakhstan',
    "Lao People's Democratic Republic",
    'Lebanon',
    'Saint Lucia',
    'Liechtenstein',
    'Sri Lanka',
    'Liberia',
    'Lesotho',
    'Lithuania',
    'Luxembourg',
    'Latvia',
    'Libya',
    'Morocco',
    'Monaco',
    'Moldova, Republic of',
    'Montenegro',
    'Saint Martin (Frenchart)',
    'Madagascar',
    'Marshall Islands',
    'Macedonia, the Former Yugoslav Republic of',
    'Mali',
    'Myanmar',
    'Mongolia',
    'Macao',
    'Northern Mariana Islands',
    'Martinique',
    'Mauritania',
    'Montserrat',
    'Malta',
    'Mauritius',
    'Maldives',
    'Malawi',
    'Mexico',
    'Malaysia',
    'Mozambique',
    'Namibia',
    'New Caledonia',
    'Niger',
    'Norfolk Island',
    'Nigeria',
    'Nicaragua',
    'Netherlands',
    'Norway',
    'Nepal',
    'Nauru',
    'Niue',
    'New Zealand',
    'Oman',
    'Panama',
    'Peru',
    'French Polynesia',
    'Papua New Guinea',
    'Philippines',
    'Pakistan',
    'Poland',
    'Saint Pierre and Miquelon',
    'Pitcairn',
    'Puerto Rico',
    'Palestine, State of',
    'Portugal',
    'Palau',
    'Paraguay',
    'Qatar',
    'Reunion',
    'Romania',
    'Serbia',
    'Russian Federation',
    'Rwanda',
    'Saudi Arabia',
    'Solomon Islands',
    'Seychelles',
    'Sudan',
    'Sweden',
    'Singapore',
    'Saint Helena',
    'Slovenia',
    'Svalbard and Jan Mayen',
    'Slovakia',
    'Sierra Leone',
    'San Marino',
    'Senegal',
    'Somalia',
    'Suriname',
    'South Sudan',
    'Sao Tome and Principe',
    'El Salvador',
    'Sint Maarten (Dutchart)',
    'Syrian Arab Republic',
    'Swaziland',
    'Turks and Caicos Islands',
    'Chad',
    'French Southern Territories',
    'Togo',
    'Thailand',
    'Tajikistan',
    'Tokelau',
    'Timor-Leste',
    'Turkmenistan',
    'Tunisia',
    'Tonga',
    'Turkey',
    'Trinidad and Tobago',
    'Tuvalu',
    'Taiwan',
    'United Republic of Tanzania',
    'Ukraine',
    'Uganda',
    'United States',
    'Uruguay',
    'Uzbekistan',
    'Holy See (Vatican City State)',
    'Saint Vincent and the Grenadines',
    'Venezuela',
    'British Virgin Islands',
    'US Virgin Islands',
    'Vietnam',
    'Vanuatu',
    'Wallis and Futuna',
    'Samoa',
    'Kosovo',
    'Yemen',
    'Mayotte',
    'South Africa',
    'Zambia',
    'Zimbabwe',
  ]