import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { methodOfPaymentSelection, termsAndConditionsSelection, signatureSelection, descSelection } from '../../components/options/Options'
import { NumFormatInput } from '../../components/NumberFormatCustom'

import { CustomField } from './CustomField'

import { useStyles } from '../../styles/invoices/Styles.Textfield'

export const EditorP3 = (props) => {
    const classes = useStyles()

    const {
        id,
        customer_name,
        desc01,
        desc02,
        desc03,
        desc04,
        desc05,
        desc06,
        desc07,
        desc08,
        desc09,
        desc10,
        amount01,
        amount02,
        amount03,
        amount04,
        amount05,
        amount06,
        amount07,
        amount08,
        amount09,
        amount10,
        amount_paid,
        deposit_due,
        deposit_amount,
        final_payment_due,
        final_payment_amount,
        currency,
        method_of_payment,
        credit_card_url,
        rewrite_payment_term,
        rewrite_remark,
        terms_and_conditions,
        signature,
    } = props.invoices ? props.invoices : ''

    const subtotal = [amount01, amount02, amount03, amount04, amount05, amount06, amount07, amount08, amount09, amount10].map(Number).reduce((a, b) => a + b, 0)
    const finalamount = subtotal - Number(amount_paid)

    const editable = props.editable

    return (<Grid container spacing={3}>

        <Grid item xs={12} sm={6}><CustomField label="Customer Name" disabled customs={{ type: 'freestyle', id: id, propval: customer_name, }} editable={editable} /></Grid>

        <Grid item xs={12} sm={4}><CustomField label="Method of Payment" customs={{ type: 'selector', id: id, propkey: 'method_of_payment', propval: method_of_payment, options: methodOfPaymentSelection, }} editable={editable} /></Grid>
        <Grid item xs={12} sm={2}><CustomField label="Currency" disabled customs={{ type: 'freestyle', id: id, propval: "THB" }} editable={editable} /></Grid>

        <Grid item xs={12} sm={6}><CustomField label="Deposit Due" clearable customs={{ type: 'date', id: id, propkey: 'deposit_due', propval: deposit_due, }} editable={editable} /></Grid>
        <Grid item xs={12} sm={6}><CustomField label="Deposit Amount" customs={{ type: 'freestyle', id: id, propkey: 'deposit_amount', propval: deposit_amount, formatter: NumFormatInput }} editable={editable} /></Grid>

        <Grid item xs={12} sm={6}><CustomField label="Final Due" clearable customs={{ type: 'date', id: id, propkey: 'final_payment_due', propval: final_payment_due, }} editable={editable} /></Grid>
        <Grid item xs={12} sm={6}><CustomField label="Final Amount" customs={{ type: 'freestyle', id: id, propkey: 'final_payment_amount', propval: final_payment_amount, formatter: NumFormatInput }} editable={editable} /></Grid>

        {/* Description & Amount */}
        <Grid container item className={classes.tabular}>
            <Grid container item xs={12} sm={12}>
                <Grid item xs={8} sm={8}><Typography className={classes.header}>Description</Typography></Grid>
                <Grid item xs={4} sm={4}><Typography className={classes.header}>Amount</Typography></Grid>
            </Grid>

            <Grid container item xs={12} sm={12}>
                <Grid item xs={8} sm={8}><CustomField customs={{ type: 'selector', id: id, propkey: 'desc01', propval: desc01, options: descSelection, }} editable={editable} /></Grid>
                <Grid item xs={4} sm={4}><CustomField customs={{ type: 'freestyle', id: id, propkey: 'amount01', propval: amount01, formatter: NumFormatInput }} editable={editable} /></Grid>
            </Grid>

            <Grid container item xs={12} sm={12}>
                <Grid item xs={8} sm={8}><CustomField customs={{ type: 'selector', id: id, propkey: 'desc02', propval: desc02, options: descSelection, }} editable={editable} /></Grid>
                <Grid item xs={4} sm={4}><CustomField customs={{ type: 'freestyle', id: id, propkey: 'amount02', propval: amount02, formatter: NumFormatInput }} editable={editable} /></Grid>
            </Grid>

            <Grid container item xs={12} sm={12}>
                <Grid item xs={8} sm={8}><CustomField customs={{ type: 'selector', id: id, propkey: 'desc03', propval: desc03, options: descSelection, }} editable={editable} /></Grid>
                <Grid item xs={4} sm={4}><CustomField customs={{ type: 'freestyle', id: id, propkey: 'amount03', propval: amount03, formatter: NumFormatInput }} editable={editable} /></Grid>
            </Grid>

            <Grid container item xs={12} sm={12}>
                <Grid item xs={8} sm={8}><CustomField customs={{ type: 'selector', id: id, propkey: 'desc04', propval: desc04, options: descSelection, }} editable={editable} /></Grid>
                <Grid item xs={4} sm={4}><CustomField customs={{ type: 'freestyle', id: id, propkey: 'amount04', propval: amount04, formatter: NumFormatInput }} editable={editable} /></Grid>
            </Grid>

            <Grid container item xs={12} sm={12}>
                <Grid item xs={8} sm={8}><CustomField customs={{ type: 'selector', id: id, propkey: 'desc05', propval: desc05, options: descSelection, }} editable={editable} /></Grid>
                <Grid item xs={4} sm={4}><CustomField customs={{ type: 'freestyle', id: id, propkey: 'amount05', propval: amount05, formatter: NumFormatInput }} editable={editable} /></Grid>
            </Grid>

            <Grid container item xs={12} sm={12}>
                <Grid item xs={8} sm={8}><CustomField customs={{ type: 'selector', id: id, propkey: 'desc06', propval: desc06, options: descSelection, }} editable={editable} /></Grid>
                <Grid item xs={4} sm={4}><CustomField customs={{ type: 'freestyle', id: id, propkey: 'amount06', propval: amount06, formatter: NumFormatInput }} editable={editable} /></Grid>
            </Grid>

            <Grid container item xs={12} sm={12}>
                <Grid item xs={8} sm={8}><CustomField customs={{ type: 'selector', id: id, propkey: 'desc07', propval: desc07, options: descSelection, }} editable={editable} /></Grid>
                <Grid item xs={4} sm={4}><CustomField customs={{ type: 'freestyle', id: id, propkey: 'amount07', propval: amount07, formatter: NumFormatInput }} editable={editable} /></Grid>
            </Grid>

            <Grid container item xs={12} sm={12}>
                <Grid item xs={8} sm={8}><CustomField customs={{ type: 'selector', id: id, propkey: 'desc08', propval: desc08, options: descSelection, }} editable={editable} /></Grid>
                <Grid item xs={4} sm={4}><CustomField customs={{ type: 'freestyle', id: id, propkey: 'amount08', propval: amount08, formatter: NumFormatInput }} editable={editable} /></Grid>
            </Grid>

            <Grid container item xs={12} sm={12}>
                <Grid item xs={8} sm={8}><CustomField customs={{ type: 'selector', id: id, propkey: 'desc09', propval: desc09, options: descSelection, }} editable={editable} /></Grid>
                <Grid item xs={4} sm={4}><CustomField customs={{ type: 'freestyle', id: id, propkey: 'amount09', propval: amount09, formatter: NumFormatInput }} editable={editable} /></Grid>
            </Grid>

            <Grid container item xs={12} sm={12}>
                <Grid item xs={8} sm={8}><CustomField customs={{ type: 'selector', id: id, propkey: 'desc10', propval: desc10, options: descSelection, }} editable={editable} /></Grid>
                <Grid item xs={4} sm={4}><CustomField customs={{ type: 'freestyle', id: id, propkey: 'amount10', propval: amount10, formatter: NumFormatInput }} editable={editable} /></Grid>
            </Grid>
        </Grid>

        <Grid container item xs={12} sm={12} className={classes.tabularbtm}>
            <Grid item xs={12} sm={4}><CustomField label="SUBTOTAL" disabled value={subtotal} customs={{ type: 'freestyle', id: id, formatter: NumFormatInput }} editable={editable} /></Grid>
        </Grid>

        <Grid container item xs={12} sm={12} className={classes.tabularbtm}>
            <Grid item xs={12} sm={4}><CustomField label="Amount Paid" customs={{ type: 'freestyle', id: id, propkey: 'amount_paid', propval: amount_paid, formatter: NumFormatInput }} editable={editable} /></Grid>
        </Grid>

        <Grid container item xs={12} sm={12} className={classes.tabularbtm}>
            <Grid item xs={12} sm={4}><CustomField label="Total Amount" disabled value={finalamount} customs={{ type: 'freestyle', id: id, formatter: NumFormatInput }} editable={editable} /></Grid>
        </Grid>
        {/* Payment Information */}

        <Grid item xs={12} sm={12}><CustomField label="Credit Card Url" customs={{ type: 'freestyle', id: id, propkey: 'credit_card_url', propval: credit_card_url, }} editable={editable} /></Grid>
        <Grid item xs={12} sm={6}><CustomField label="Terms of Payment" multiline rows={5} customs={{ type: 'freestyle', id: id, propkey: 'rewrite_payment_term', propval: rewrite_payment_term, }} editable={editable} /></Grid>
        <Grid item xs={12} sm={6}><CustomField label="Remarks" multiline rows={5} customs={{ type: 'freestyle', id: id, propkey: 'rewrite_remark', propval: rewrite_remark, }} editable={editable} /></Grid>
        <Grid item xs={12} sm={6}><CustomField label="Terms & Conditions" customs={{ type: 'selector', id: id, propkey: 'terms_and_conditions', propval: terms_and_conditions, options: termsAndConditionsSelection, }} editable={editable} /></Grid>
        <Grid item xs={12} sm={6}><CustomField label="Authorized Signature" customs={{ type: 'selector', id: id, propkey: 'signature', propval: signature, options: signatureSelection, }} editable={editable} /></Grid>

    </Grid>
    )
}