import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Link as LinkRouter } from 'react-router-dom'

import { unwrapResult } from '@reduxjs/toolkit'
import { registerUser, authSelector, clearState } from './authSlice'
import { useHistory } from 'react-router-dom'
//import toast from 'react-hot-toast'

import { simpleLogger } from '../app/appSlice'

import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import Copyright from '../../components/Copyright'

import { useStyles } from '../../styles/auth/Styles.Main'

const Register = () => {
    const classes = useStyles()

    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const [addRequestStatus, setAddRequestStatus] = useState('idle')

    const { isFetching, isSuccess, isError, errorMessage } = useSelector(
        authSelector
    )

    const onUsernameChanged = e => setUsername(e.target.value)
    const onEmailChanged = e => setEmail(e.target.value)
    const onPasswordChanged = (e) => setPassword(e.target.value)

    const dispatch = useDispatch()
    const history = useHistory()

    const canSave = [username, email, password].every(Boolean) && addRequestStatus === 'idle'

    const onRegisterPostClicked = async () => {
        if (canSave) {
            try {
                setAddRequestStatus('pending')
                const resultAction = await dispatch(
                    registerUser({
                        //username: username,
                        username,
                        email,
                        password,
                    })
                )
                const unwrappedAuth = unwrapResult(resultAction)
                //console.log(unwrappedAuth)
                dispatch(simpleLogger(['A new account, ' + username + ', has been added successfully!', 'success', Date.now()]))
            } catch (err) {
                dispatch(simpleLogger([err, 'error', Date.now()]))
            } finally {
                setAddRequestStatus('idle')
            }
        }
    }

    useEffect(() => {
        return () => {
            dispatch(clearState())
        }
    }, [])

    useEffect(() => {
        if (isSuccess) {
            dispatch(clearState())
            history.push('/signin')
        }

        if (isError) {
            //toast.error(errorMessage)
            dispatch(clearState())
        }
    }, [isSuccess, isError])

    return (
        <Grid container component="main" className={classes.root}>
            <Grid item xs={false} sm={4} md={7} className={classes.image} />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <a href="/dashboard"><img alt="logo" src="https://www.siam.luxe/wp-content/uploads/2022/01/logo.png" /></a>
                    <Typography component="h1" variant="h5">
                        Register a New Account
                    </Typography>
                    <form className={classes.form} noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="text"
                            inputProps={{
                                autoComplete: 'new-password',
                                form: {
                                    autoComplete: 'off',
                                },
                            }}
                            autoFocus
                            value={username}
                            onChange={onUsernameChanged}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            inputProps={{
                                autoComplete: 'new-password',
                                form: {
                                    autoComplete: 'off',
                                },
                            }}
                            value={email}
                            onChange={onEmailChanged}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="new-password"
                            value={password}
                            onChange={onPasswordChanged}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={true} value="remember" color="primary" />}
                            label="Remember me"
                        />
                        <Button
                            //type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={onRegisterPostClicked}
                        >
                            Register
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link variant="body2" component={LinkRouter} to="signin">
                                    {"Already have an account? Sign In"}
                                </Link>
                            </Grid>
                        </Grid>
                        <Box mt={5}>
                            <Copyright />
                        </Box>
                    </form>
                </div>
            </Grid>
        </Grid>
    )
}

export default Register