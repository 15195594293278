import { Switch } from 'react-router-dom'
import { PrivateRoute } from '../helpers/PrivateRoute';

import Summary from './pages/Summary'
import Bk from './pages/Bk'
import Customers from './pages/Customers'
import Tourcode from './pages/Tourcode'
import Suppliers from './pages/Suppliers'
import Invoices from './pages/Invoices'
import Receipts from './pages/Receipts'
import WHT from './pages/WHT'
import Main from './pages/Main'

import Container from '@material-ui/core/Container';

export default function CAppRoutes() {
  return (
    <div className="App">
      <Container maxWidth="lg">
        <Switch>

          <PrivateRoute exact path="/" component={Main} />
          <PrivateRoute exact path="/dashboard" component={Main} />
          <PrivateRoute exact path="/summary" component={Summary} />
          <PrivateRoute exact path="/bk" component={Bk} />
          <PrivateRoute exact path="/customers" component={Customers} />
          <PrivateRoute exact path="/tourcode" component={Tourcode} />
          <PrivateRoute exact path="/suppliers" component={Suppliers} />
          <PrivateRoute exact path="/invoices" component={Invoices} />
          <PrivateRoute exact path="/receipts" component={Receipts} />
          <PrivateRoute exact path="/wht" component={WHT} />

        </Switch>

      </Container>
    </div>
  )
}