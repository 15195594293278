import { Link, useloc, useHistory } from 'react-router-dom'

import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import DashboardIcon from '@material-ui/icons/Dashboard'

import MenuBookIcon from '@material-ui/icons/MenuBook'
import FaceIcon from '@material-ui/icons/Face'
import ViewListIcon from '@material-ui/icons/ViewList'
import StorefrontIcon from '@material-ui/icons/Storefront'

import FileCopyIcon from '@material-ui/icons/FileCopy'
import ReceiptIcon from '@material-ui/icons/Receipt'
import DescriptionIcon from '@material-ui/icons/Description'

import ExitToAppIcon from '@material-ui/icons/ExitToApp'

export const Navigation = (props) => {
  const { item, location } = props

  switch (item) {
    case 'top': return (
      <div>
        <ListItem button component={Link} to="/">
          <ListItemIcon>
            <DashboardIcon color={location == '/' ? 'primary' : 'inherit'} />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        <ListItem button component={Link} to="/bk">
          <ListItemIcon>
            <MenuBookIcon color={location == '/bk' ? 'primary' : 'inherit'} />
          </ListItemIcon>
          <ListItemText primary="Bookkeeper" />
        </ListItem>
        <ListItem button component={Link} to="/customers">
          <ListItemIcon>
            <FaceIcon color={location == '/customers' ? 'primary' : 'inherit'} />
          </ListItemIcon>
          <ListItemText primary="Customers" />
        </ListItem>
        <ListItem button component={Link} to="/suppliers">
          <ListItemIcon>
            <StorefrontIcon color={location == '/suppliers' ? 'primary' : 'inherit'} />
          </ListItemIcon>
          <ListItemText primary="Suppliers" />
        </ListItem>
        <ListItem button component={Link} to="/tourcode">
          <ListItemIcon>
            <ViewListIcon color={location == '/tourcode' ? 'primary' : 'inherit'} />
          </ListItemIcon>
          <ListItemText primary="Tour Code" />
        </ListItem>
      </div>
    )
    case 'middle': return (
      <div>
        <ListItem button component={Link} to="/invoices">
          <ListItemIcon>
            <FileCopyIcon color={location == '/invoices' ? 'primary' : 'inherit'} />
          </ListItemIcon>
          <ListItemText primary="Invoices" />
        </ListItem>
        <ListItem button component={Link} to="/receipts">
          <ListItemIcon>
            <ReceiptIcon color={location == '/receipts' ? 'primary' : 'inherit'} />
          </ListItemIcon>
          <ListItemText primary="Receipts" />
        </ListItem>
        <ListItem button component={Link} to="/wht">
          <ListItemIcon>
            <DescriptionIcon color={location == '/wht' ? 'primary' : 'inherit'} />
          </ListItemIcon>
          <ListItemText primary="WHT Docs" />
        </ListItem>
      </div>
    )
    case 'bottom': return (
      <div>
        <ListItem button component={Link} to="/summary">
          <ListItemIcon>
            <MenuBookIcon color={location == '/summary' ? 'primary' : 'inherit'} />
          </ListItemIcon>
          <ListItemText primary="Summary" />
        </ListItem>
      </div>
    )
    case 'signout': return (
      SignOut()
    )
  }
}

export const SignOut = () => {
  const history = useHistory()

  const onSignOut = () => {
    history.push('/signin')
    localStorage.removeItem('token')
  }

  return (
    <ListItem button onClick={onSignOut}>
      <ListItemIcon>
        <ExitToAppIcon />
      </ListItemIcon>
      <ListItemText primary="Sign Out" />
    </ListItem>
  )
}