import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { authSelector, fetchUserBytoken, clearState } from './authSlice'
import { useHistory } from 'react-router-dom'

import clsx from 'clsx'

import { Link as LinkRouter, useLocation } from 'react-router-dom'

import CssBaseline from '@material-ui/core/CssBaseline'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Badge from '@material-ui/core/Badge'
import Container from '@material-ui/core/Container'
import Link from '@material-ui/core/Link'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import NotificationsIcon from '@material-ui/icons/Notifications'

import { Navigation } from '../../components/Navigation'
import CAppRoutes from '../../views/CAppRoutes'

import { useStyles } from '../../styles/auth/Styles.Dashboard'

const Dashboard = () => {
    const classes = useStyles()

    const [open, setOpen] = useState(false)
    const { username, email } = useSelector(authSelector)
    const { isFetching, isError } = useSelector(authSelector)

    const location = useLocation()
    var title = ''
    switch (location.pathname) {
        case '/bk': title = 'Bookkeeper'
            break
        case '/customers': title = 'Customers'
            break
        case '/suppliers': title = 'Suppliers'
            break
        case '/tourcode': title = 'Tour Code'
            break
        case '/invoices': title = 'Invoices'
            break
        case '/receipts': title = 'Receipts'
            break
        case '/wht': title = 'Withholding Taxes'
            break
        case '/summary': title = 'Summary'
            break
        default: title = 'Welcome ' + username
            break
    }

    const handleDrawerOpen = () => { setOpen(true) }
    const handleDrawerClose = () => { setOpen(false) }

    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        dispatch(fetchUserBytoken({ token: localStorage.getItem('token') }))
    }, [])

    useEffect(() => {
        if (isError) {
            dispatch(clearState())
            history.push('/signin')
        }
    }, [isError])

    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight)

    return (
        <div className={classes.root}>
            <CssBaseline />
            {isFetching ? (
                <Backdrop className={classes.backdrop} open={true}>
                    <h1>Loading...</h1>
                    <CircularProgress color="inherit" />
                </Backdrop>
            ) : (
                <>
                    <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                        <Toolbar className={classes.toolbar}>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                            >
                                <MenuIcon />
                            </IconButton>

                            <Typography component="h1" variant="h6" noWrap className={classes.title}>
                                <Link component={LinkRouter} to="/" color="inherit">
                                    {title}
                                </Link>
                            </Typography>
                            <IconButton color="inherit">
                                <Badge badgeContent={7} color="error">
                                    <NotificationsIcon />
                                </Badge>
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <Drawer
                        variant="permanent"
                        classes={{
                            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                        }}
                        open={open}
                    >
                        <div className={classes.toolbarIcon}>
                            <IconButton onClick={handleDrawerClose}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </div>
                        <Divider />
                        <List><Navigation item='top' location={location.pathname} /></List>
                        <Divider />
                        <List><Navigation item='middle' location={location.pathname} /></List>
                        <Divider />
                        <List><Navigation item='bottom' location={location.pathname} /></List>
                        <Divider />
                        <List><Navigation item='signout' location={location.pathname} /></List>
                    </Drawer>
                    <main className={classes.content}>
                        <div className={classes.appBarSpacer} />
                        <Container maxWidth="lg" className={classes.container}>
                            <CAppRoutes />
                        </Container>
                    </main>
                    <footer>
                        {/* <Snacks /> // probably no longer needed */}
                    </footer>
                </>
            )}
        </div>
    )
}

export default Dashboard