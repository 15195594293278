import { useDispatch, useSelector } from 'react-redux'

import { adderClosed } from '../app/appSlice'

import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'

import { AdderP1 } from './AdderP1'

export const Adder = (props) => {
    const { prefilled } = props

    const app = useSelector(state => state.app.adder)
    const dispatch = useDispatch()

    return (

        <Dialog
            open={Boolean(app)}
            onClose={() => dispatch(adderClosed())}
            aria-labelledby="form-dialog-title"
            // fullWidth={true}
            // maxWidth="md"
            scroll="body"
        >
            <DialogTitle disableTypography><Typography variant="h4" align="center">Add New Bk</Typography></DialogTitle>
            <DialogContent>
                {/* <DialogContentText>
                    To add a new entry, please fill out the following details:
                </DialogContentText> */}
                <form noValidate autoComplete="new-password" style={{ padding: 6 }}>
                    <AdderP1 prefilled={prefilled} />
                </form>
            </DialogContent>
        </Dialog >
    )
}