import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { selectAllInvoices, fetchInvoices } from './invoicesSlice'
//import { invoicesType } from '../../components/SelectOptions'
import { editorOpened } from '../app/appSlice'

import { DataGrid } from '@material-ui/data-grid'

import useMediaQuery from '@material-ui/core/useMediaQuery'

import { NumFormatDisplay } from '../../components/NumberFormatCustom'
import { HorizontalLoadingOverlay } from '../../components/HorizontalLoadingOverlay'

import { useStyles } from '../../styles/invoices/Styles.DataList'

export const InvoicesPage = (props) => {
    const classes = useStyles()
    const isMobile = useMediaQuery('(max-width:1024px)')

    const dispatch = useDispatch()
    const invoices = useSelector(selectAllInvoices)

    const invoicesStatus = useSelector(state => state.invoices.status)
    const error = useSelector(state => state.invoices.error)

    const fetch = props.fetch

    useEffect(() => {
        if (invoicesStatus === 'idle') {
            dispatch(fetchInvoices({
                useOption: true,
                options: fetch,
                //start_date: '',
                //end_date: '',
            }))
        }
    }, [invoicesStatus, dispatch])

    let content
    let columns

    if (isMobile) {
        columns = [
            {
                field: 'virtual_id',
                headerName: '#',
                width: 50,
                disableColumnMenu: true,
                sortable: false,
                valueGetter: (params) => (
                    params.rowIndex + 1
                )
            },
            {
                field: 'created_date',
                headerName: 'Date',
                width: 120,
                type: 'date',
                valueFormatter: (params) => (
                    new Date(params.row.created_date).toLocaleString("en-US", { year: 'numeric', month: 'short', day: 'numeric' })
                )
            },
            {
                field: 'customer_name',
                headerName: 'Customer Name',
                width: 250,
                disableColumnMenu: true,
                sortable: false
            },
            {
                field: 'invoice_no',
                headerName: 'Invoice No.',
                width: 120,
                disableColumnMenu: true,
                sortable: false
            },
            {
                field: 'revision',
                headerName: 'Rev.',
                width: 70,
                disableColumnMenu: true,
                sortable: false
            },
            {
                field: 'deposit_due',
                headerName: 'Dep. Due',
                width: 120,
                type: 'date',
                valueFormatter: (params) => (
                    params.row.deposit_due ? new Date(params.row.deposit_due).toLocaleString("en-US", { year: 'numeric', month: 'short', day: 'numeric' }) : ''
                )
            },
            {
                field: 'deposit_amount',
                headerName: 'Dep. Amt',
                type: 'number',
                width: 120,
                disableColumnMenu: true,
                sortable: false,
                valueFormatter: (params) => (
                    NumFormatDisplay(params.row.deposit_amount)
                )
            },
            {
                field: 'final_payment_due',
                headerName: 'Final Due',
                width: 120,
                type: 'date',
                valueFormatter: (params) => (
                    params.row.final_payment_due ? new Date(params.row.final_payment_due).toLocaleString("en-US", { year: 'numeric', month: 'short', day: 'numeric' }) : ''
                )
            },
            {
                field: 'final_payment_amount',
                headerName: 'Final Amt',
                type: 'number',
                width: 120,
                disableColumnMenu: true,
                sortable: false,
                valueFormatter: (params) => (
                    NumFormatDisplay(params.row.final_payment_amount)
                )
            },
            {
                field: 'amount_paid',
                headerName: 'Amount Paid',
                type: 'number',
                width: 120,
                disableColumnMenu: true,
                sortable: false,
                valueFormatter: (params) => (
                    NumFormatDisplay(params.row.amount_paid)
                )
            },
        ]
    } else {
        columns = [
            {
                field: 'virtual_id',
                headerName: '#',
                width: 50,
                disableColumnMenu: true,
                sortable: false,
                valueGetter: (params) => (
                    params.rowIndex + 1
                )
            },
            {
                field: 'created_date',
                headerName: 'Date',
                width: 120,
                type: 'date',
                valueFormatter: (params) => (
                    new Date(params.row.created_date).toLocaleString("en-US", { year: 'numeric', month: 'short', day: 'numeric' })
                )
            },
            {
                field: 'customer_name',
                headerName: 'Customer Name',
                flex: 12,
                disableColumnMenu: true,
                sortable: false
            },
            {
                field: 'invoice_no',
                headerName: 'Invoice No.',
                width: 120,
                disableColumnMenu: true,
                sortable: false
            },
            {
                field: 'revision',
                headerName: 'Rev.',
                width: 70,
                disableColumnMenu: true,
                sortable: false
            },
            {
                field: 'deposit_due',
                headerName: 'Dep. Due',
                width: 120,
                type: 'date',
                valueFormatter: (params) => (
                    params.row.deposit_due ? new Date(params.row.deposit_due).toLocaleString("en-US", { year: 'numeric', month: 'short', day: 'numeric' }) : ''
                )
            },
            {
                field: 'deposit_amount',
                headerName: 'Dep. Amt',
                type: 'number',
                width: 120,
                disableColumnMenu: true,
                sortable: false,
                valueFormatter: (params) => (
                    NumFormatDisplay(params.row.deposit_amount)
                )
            },
            {
                field: 'final_payment_due',
                headerName: 'Final Due',
                width: 120,
                type: 'date',
                valueFormatter: (params) => (
                    params.row.final_payment_due ? new Date(params.row.final_payment_due).toLocaleString("en-US", { year: 'numeric', month: 'short', day: 'numeric' }) : ''
                )
            },
            {
                field: 'final_payment_amount',
                headerName: 'Final Amt',
                type: 'number',
                width: 120,
                disableColumnMenu: true,
                sortable: false,
                valueFormatter: (params) => (
                    NumFormatDisplay(params.row.final_payment_amount)
                )
            },
            {
                field: 'amount_paid',
                headerName: 'Amount Paid',
                type: 'number',
                width: 120,
                disableColumnMenu: true,
                sortable: false,
                valueFormatter: (params) => (
                    NumFormatDisplay(params.row.amount_paid)
                )
            },
        ]
    }

    const rows = invoices

    content = <div className={classes.root} style={{ height: 400, width: '100%' }}>

        <DataGrid
            autoHeight
            sortingOrder={['asc', 'desc']}
            rows={rows}
            columns={columns}
            pageSize={100}
            density="compact"
            rowHeight={48}
            disableMultipleSelection={true}
            hideFooterSelectedRowCount={true}
            onRowClick={(params) => dispatch(editorOpened({ 'editor': params.row.id }))}
            components={{
                LoadingOverlay: HorizontalLoadingOverlay,
            }}
            loading={Boolean(invoicesStatus === 'loading')}
        />

    </div>

    return (
        <>
            {content}
        </>
    )
}