import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'

import { saveSuppliers } from './suppliersSlice'

import { DateField } from '../../components/cfields/DateField'
import { FreeField } from '../../components/cfields/FreeField'
import { SelectorField } from '../../components/cfields/SelectorField'

export const CustomField = (props) => {
    const { customs, ...others } = props
    const { type, id, propkey, propval, options, formatter } = customs

    const [value, setValue] = useState(propval || '')
    const [inputValue, setInputValue] = useState(propval || '')
    const [addRequestStatus, setAddRequestStatus] = useState('idle')

    const saveValue = type != 'selector' ? value : inputValue

    const dispatch = useDispatch()

    const handleValue = e => { setValue(e.target.value) }
    const handleValueSelector = (event, newValue) => { setValue(newValue) }
    const handleInputValue = (event, newInputValue) => { setInputValue(newInputValue) }

    const canSave = Boolean(props.editable == 'true')

    const instantSave = async () => {
        if (canSave) {
            try {
                setAddRequestStatus('pending')
                const resultAction = await dispatch(
                    saveSuppliers({
                        id: id,
                        [propkey]: saveValue ? saveValue : null
                    })
                )
                unwrapResult(resultAction)
            } catch (err) {
                setValue(propval || '')
            } finally {
                setAddRequestStatus('idle')
            }
        }
    }

    switch (type) {
        case 'date':
            return (
                <DateField
                    value={value}
                    onChange={setValue}
                    onBlur={instantSave}
                    {...others}
                />
            )
        case 'freestyle':
            return (
                <FreeField
                    value={value}
                    onChange={handleValue}
                    onBlur={instantSave}
                    formatter={formatter}
                    {...others}
                />
            )
        case 'selector':
            return (
                <SelectorField
                    customs={{
                        value: value,
                        inputValue: inputValue,
                        onChange: handleValueSelector,
                        onInputChange: handleInputValue,
                        options: options,
                    }}
                    onBlur={instantSave}
                    {...others}
                />
            )
        default:
            return (
                <FreeField
                    value={value}
                    onChange={handleValue}
                    onBlur={instantSave}
                    formatter={formatter}
                    {...others}
                />
            )
    }
}