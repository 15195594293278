import Grid from '@material-ui/core/Grid'

import { CustomField } from './CustomField'

export const EditorP2 = (props) => {
    const {
        id,
        entry_date,
        tour_code,
        entry_desc,
        entry_customer,
        bp,
    } = props.tourcode ? props.tourcode : ''

    const editable = props.editable

    return (<Grid container spacing={3}>

        <Grid item xs={12} sm={12}><CustomField label="Description" disabled customs={{ type: 'freestyle', id: id, propval: entry_desc, }} editable={editable} /></Grid>
        <Grid item xs={12} sm={12}><CustomField label="BP" customs={{ type: 'richtext', id: id, propkey: 'bp', propval: bp, }} editable={editable} /></Grid>

    </Grid>
    )
}