import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

export const SelectorField = (props) => {
    const { customs, editable, ...others } = props

    return (
        <Autocomplete
            renderInput={(params) => Boolean(editable == 'true') ?
                <TextField {...params}
                    size="small"
                    variant="outlined"
                    fullWidth
                    {...others}
                /> :
                <TextField {...params}
                    size="small"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                        readOnly: editable == 'false',
                    }}
                    {...others}
                />
            }
            freeSolo
            disableClearable
            autoHighlight
            {...customs}
        />
    )
}