import TextField from '@material-ui/core/TextField'

export const UncontrolledFreeField = (props) => {
    const { customs, ...others } = props
    const { value, onChange } = customs

    return (
        <TextField
            size="small"
            variant="outlined"
            inputProps={{
                autoComplete: 'new-password',
                form: {
                    autoComplete: 'off',
                },
            }}
            fullWidth
            value={value}
            onChange={onChange}
            {...others}
        />
    )
}