import TextField from '@material-ui/core/TextField'

export const FreeField = (props) => {
    const { formatter, editable, ...others } = props

    return (
        <TextField
            size="small"
            variant="outlined"
            fullWidth
            InputProps={{
                autoComplete: 'new-password',
                form: {
                    autoComplete: 'off',
                },
                inputComponent: formatter,
                readOnly: editable == 'false',
            }}
            {...others}
        />
    )
}