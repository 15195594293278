import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { selectAllReceipts, fetchReceipts } from './receiptsSlice'
//import { receiptsType } from '../../components/SelectOptions'
import { editorOpened, deleterOpened } from '../app/appSlice'

import { DataGrid } from '@material-ui/data-grid'

import useMediaQuery from '@material-ui/core/useMediaQuery'

import { NumFormatDisplay } from '../../components/NumberFormatCustom'
import { HorizontalLoadingOverlay } from '../../components/HorizontalLoadingOverlay'

import { useStyles } from '../../styles/receipts/Styles.DataList'

export const ReceiptsPage = (props) => {
    const classes = useStyles()
    const isMobile = useMediaQuery('(max-width:1024px)')

    const dispatch = useDispatch()
    const receipts = useSelector(selectAllReceipts)

    const receiptsStatus = useSelector(state => state.receipts.status)
    const error = useSelector(state => state.receipts.error)

    const fetch = props.fetch

    useEffect(() => {
        if (receiptsStatus === 'idle') {
            dispatch(fetchReceipts({
                useOption: true,
                options: fetch,
                //start_date: '',
                //end_date: '',
            }))
        }
    }, [receiptsStatus, dispatch])

    let content
    let columns

    if (isMobile) {
        columns = [
            {
                field: 'virtual_id',
                headerName: '#',
                width: 50,
                disableColumnMenu: true,
                sortable: false,
                valueGetter: (params) => (
                    params.rowIndex + 1
                )
            },
            {
                field: 'created_date',
                headerName: 'Receipt Date',
                width: 150,
                type: 'date',
                valueFormatter: (params) => (
                    new Date(params.getValue('created_date')).toLocaleString("en-US", { year: 'numeric', month: 'short', day: 'numeric' })
                )
            },
            {
                field: 'customer_name',
                headerName: 'Customer Name',
                width: 250,
                disableColumnMenu: true,
                sortable: false
            },
            {
                field: 'invoice_no',
                headerName: 'Invoice No.',
                width: 150,
                disableColumnMenu: true,
                sortable: false
            },
            {
                field: 'receipt_no',
                headerName: 'Receipt No.',
                width: 150,
                disableColumnMenu: true,
                sortable: false
            },
            {
                field: 'amount_paid',
                headerName: 'Amount Paid',
                type: 'number',
                width: 150,
                disableColumnMenu: true,
                sortable: false,
                valueFormatter: (params) => (
                    NumFormatDisplay(params.getValue('amount_paid'))
                )
            },
        ]
    } else {
        columns = [
            {
                field: 'virtual_id',
                headerName: '#',
                width: 50,
                disableColumnMenu: true,
                sortable: false,
                valueGetter: (params) => (
                    params.rowIndex + 1
                )
            },
            {
                field: 'created_date',
                headerName: 'Receipt Date',
                width: 150,
                type: 'date',
                valueFormatter: (params) => (
                    new Date(params.getValue('created_date')).toLocaleString("en-US", { year: 'numeric', month: 'short', day: 'numeric' })
                )
            },
            {
                field: 'customer_name',
                headerName: 'Customer Name',
                flex: 4,
                disableColumnMenu: true,
                sortable: false
            },
            {
                field: 'invoice_no',
                headerName: 'Invoice No.',
                width: 150,
                disableColumnMenu: true,
                sortable: false
            },
            {
                field: 'receipt_no',
                headerName: 'Receipt No.',
                width: 150,
                disableColumnMenu: true,
                sortable: false
            },
            {
                field: 'amount_paid',
                headerName: 'Amount Paid',
                type: 'number',
                width: 150,
                disableColumnMenu: true,
                sortable: false,
                valueFormatter: (params) => (
                    NumFormatDisplay(params.getValue('amount_paid'))
                )
            },
        ]
    }

    const rows = receipts

    content = <div className={classes.root} style={{ height: 400, width: '100%' }}>

        <DataGrid
            autoHeight
            sortingOrder={['asc', 'desc']}
            rows={rows}
            columns={columns}
            pageSize={100}
            density="compact"
            rowHeight={48}
            disableMultipleSelection={true}
            hideFooterSelectedRowCount={true}
            onRowClick={(params) => dispatch(editorOpened({ 'editor': params.row.id }))}
            components={{
                LoadingOverlay: HorizontalLoadingOverlay,
            }}
            loading={Boolean(receiptsStatus === 'loading')}
        />

    </div>

    return (
        <>
            {content}
        </>
    )
}