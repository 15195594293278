import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { useHistory } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import SaveIcon from '@material-ui/icons/Save'
import PrintIcon from '@material-ui/icons/Print'
import SendIcon from '@material-ui/icons/Send'

import useMediaQuery from '@material-ui/core/useMediaQuery'

import { editorLogger, adderLogger } from '../helpers/LoggerMsg'

import { editorClosed, adderClosed, deleterOpened } from '../features/app/appSlice'

export const SaveButton = (props) => {
    const { canParentSave, slug } = props

    const [addRequestStatus, setAddRequestStatus] = useState('idle')

    const isMobile = useMediaQuery('(max-width:1024px)')

    const dispatch = useDispatch()
    const history = useHistory()

    const canSave = canParentSave && addRequestStatus === 'idle'

    const onSavePostClicked = async () => {
        if (canSave) {
            try {
                setAddRequestStatus('pending')
                history.push(slug)
                dispatch(editorClosed())
            } catch (err) {
                dispatch(editorClosed(editorLogger(['error', err])))
            } finally {
                setAddRequestStatus('idle')
            }
        }
    }

    if (isMobile) {
        return (
            <IconButton
                aria-label="Save"
                color="primary"
                onClick={onSavePostClicked}
                disabled={!canSave}
            >
                <SaveIcon />
            </IconButton>
        )
    }
    return (
        <Button
            variant="contained"
            startIcon={<SaveIcon />}
            onClick={onSavePostClicked}
            disabled={!canSave}
        >
            Save
        </Button>
    )
}

export const CancelButton = (props) => {
    const { slug } = props

    const [addRequestStatus, setAddRequestStatus] = useState('idle')

    const dispatch = useDispatch()
    const history = useHistory()

    const canSave = addRequestStatus === 'idle'

    const onCancelPostClicked = async () => {
        if (canSave) {
            try {
                setAddRequestStatus('pending')
                history.push(slug)
                dispatch(editorClosed())
            } catch (err) {
                dispatch(editorClosed(editorLogger(['error', err])))
            } finally {
                setAddRequestStatus('idle')
            }
        }
    }

    return (<Button
        onClick={onCancelPostClicked}
    >
        Cancel
    </Button>)
}

export const CloseButton = (props) => {
    const { canParentSave, slug } = props

    const [addRequestStatus, setAddRequestStatus] = useState('idle')

    const dispatch = useDispatch()
    const history = useHistory()

    const canSave = canParentSave && addRequestStatus === 'idle'

    const onCloseButtonClicked = async () => {
        if (canSave) {
            try {
                setAddRequestStatus('pending')
                history.push(slug)
                dispatch(adderClosed())
            } catch (err) {
                dispatch(adderClosed(adderLogger(['error', err])))
            } finally {
                setAddRequestStatus('idle')
            }
        }
    }

    return (<Button
        onClick={onCloseButtonClicked}
    >
        Close
    </Button>)
}

export const AddButton = (props) => {
    const { canParentSave, ...others } = props

    return (<Button
        variant="contained"
        startIcon={<SaveIcon />}
        {...others}
    >
        Add
    </Button>)
}

export const PrintButton = (props) => {
    const { canParentSave, ...others } = props

    const isMobile = useMediaQuery('(max-width:1024px)')

    if (isMobile) {
        return (
            <IconButton
                aria-label="Print"
                color="primary"
                target="_blank"
                rel="noreferrer"
                {...others}
            >
                <PrintIcon />
            </IconButton>
        )
    }
    return (<Button
        variant="contained"
        startIcon={<PrintIcon />}
        target="_blank"
        rel="noreferrer"
        {...others}
    >
        Print
    </Button>)
}

export const SendButton = (props) => {
    const { canParentSave, ...others } = props

    const isMobile = useMediaQuery('(max-width:1024px)')

    if (isMobile) {
        return (
            <IconButton
                aria-label="Send"
                color="primary"
                target="_blank"
                rel="noreferrer"
                {...others}
            >
                <SendIcon />
            </IconButton>
        )
    }
    return (<Button
        variant="contained"
        startIcon={<SendIcon />}
        target="_blank"
        rel="noreferrer"
        {...others}
    >
        Send
    </Button>)
}