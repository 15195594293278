import { makeStyles } from '@material-ui/core/styles'

import { invoices as mainColor } from '../Styles.Color'

export const useStyles = makeStyles((theme) => ({
    root: {},
    tabular: {
        "& .MuiOutlinedInput-root": {
            borderRadius: '0px',
        },
        "& .MuiGrid-container > .MuiGrid-item:first-child .MuiOutlinedInput-notchedOutline": {
            borderRight: '0',
        },
        "& .MuiGrid-container:not(:last-child) .MuiOutlinedInput-notchedOutline": {
            borderBottom: '0',
        },
        "& .MuiGrid-container > .MuiGrid-item:last-child input": {
            textAlign: 'center',
        },
        '& .MuiInputBase-root .MuiInputBase-input.Mui-disabled': {
            color: '#00024c',
            background: '#f3f3f3b3'
        },
        '& label.MuiFormLabel-root.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.54)',
        },
    },
    tabularbtm: {
        justifyContent: 'flex-end',
        "& input": {
            textAlign: 'center',
        }
    },
    header: {
        fontWeight: 'bold',
        textAlign: 'center',
        color: theme.palette.primary.contrastText,
        background: theme.palette.primary.main,
        padding: '0.35rem',
    },

}))