import Grid from '@material-ui/core/Grid'

import { OptionsFetcher, revisionSelection, referenceSelection } from '../../components/options/Options'

import { CustomField } from './CustomField'

export const EditorP1 = (props) => {
    const {
        id,
        customer_name,
        created_date,
        invoice_no,
        revision,
        reference,
        service_for,
        single_entry,
    } = props.invoices ? props.invoices : ''

    const editable = props.editable

    return (<Grid container spacing={3}>

        <Grid item xs={12} sm={6}><CustomField label="Created Date" customs={{ type: 'date', id: id, propkey: 'created_date', propval: created_date, }} editable={editable} /></Grid>
        <Grid item xs={12} sm={6}><CustomField label="Customer Name" customs={{ type: 'selector', id: id, propkey: 'customer_name', propval: customer_name, options: OptionsFetcher({ 'slug': 'customer_name' }) }} editable={editable} /></Grid>
        <Grid item xs={12} sm={6}><CustomField label="Invoice No." customs={{ type: 'selector', id: id, propkey: 'invoice_no', propval: invoice_no, options: OptionsFetcher({ 'slug': 'tour_code', 'filter_by': customer_name }) }} editable={editable} /></Grid>
        <Grid item xs={12} sm={6}><CustomField label="Revision" customs={{ type: 'selector', id: id, propkey: 'revision', propval: revision, options: revisionSelection }} editable={editable} /></Grid>
        <Grid item xs={12} sm={6}><CustomField label="Reference" customs={{ type: 'selector', id: id, propkey: 'reference', propval: reference, options: referenceSelection }} editable={editable} /></Grid>
        <Grid item xs={12} sm={6}><CustomField label="Service For" customs={{ type: 'freestyle', id: id, propkey: 'service_for', propval: service_for, }} editable={editable} /></Grid>
        {/* <Grid item xs={12} sm={6}><CustomField label="Single Entry" disabled customs={{ type: 'freestyle', id: id, propkey: 'single_entry', propval: 'Sales Revenue', }} editable={editable} /></Grid> */}

    </Grid>
    )
}