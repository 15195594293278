import { createSlice, createAsyncThunk, createSelector, createEntityAdapter } from '@reduxjs/toolkit'
import { client } from '../../api/client'

const tourcodeAdapter = createEntityAdapter({
  //sortComparer: (a, b) => b.entry_date.localeCompare(a.entry_date),
})

const initialState = tourcodeAdapter.getInitialState({
  status: 'idle',
  error: null,
  logger: '',
})

export const fetchTourcode = createAsyncThunk(
  'tourcode/fetchTourcode',
  async (props, thunkAPI) => {
    if (props.useOption != '') {
      const options = '/' + props.options
      const response = await client.get(
        '../../api/tourcode' + options,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )

      return response.tourcode
    }
    const response = await client.get('../../api/tourcode')
    return response.tourcode
  }
)

export const addNewTourcode = createAsyncThunk(
  'tourcode/addNewTourcode',
  async (initialData, { rejectWithValue }) => {
    try {
      const response = await client.post(
        '../../api/tourcode/add',
        {
          tourcode: initialData
        },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      return response.tourcode
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const saveTourcode = createAsyncThunk(
  'tourcode/saveTourcode',
  async (initialData, { rejectWithValue }) => {
    try {
      const response = await client.post(
        '../../api/tourcode/save',
        {
          tourcode: initialData
        },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      return response.tourcode
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const deleteTourcode = createAsyncThunk(
  'tourcode/deleteTourcode',
  async (initialData, { rejectWithValue }) => {
    try {
      const response = await client.post(
        '../../api/tourcode/delete',
        {
          tourcode: initialData
        },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      return response.tourcode
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

const tourcodeSlice = createSlice({
  name: 'tourcode',
  initialState,
  reducers: {
    refetch: state => {
      state.status = 'idle'
    },
  },
  extraReducers: {
    [fetchTourcode.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchTourcode.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      // tourcodeAdapter.upsertMany(state, action.payload)
      tourcodeAdapter.setAll(state, action.payload)
    },
    [fetchTourcode.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.payload
      console.log('%cError: Unable to fetch data from the database!', 'color:red')
    },
    [addNewTourcode.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      tourcodeAdapter.addOne(state, action.payload)
    },
    [addNewTourcode.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = 'Unable to add a new entry! (' + action.error.message + ')'
    },
    [saveTourcode.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      tourcodeAdapter.upsertOne(state, action.payload)
    },
    [saveTourcode.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = 'Unable to edit this selected entry! (' + action.error.message + ')'
    },
    [deleteTourcode.fulfilled]: (state, action) => {
      const { id } = action.payload
      state.status = 'succeeded'
      tourcodeAdapter.removeOne(state, id)
    },
    [deleteTourcode.rejected]: (state, action) => {
      const { id } = action.payload
      state.status = 'failed'
      state.error = 'Unable to delete this selected entry! (' + action.error.message + ' | id: ' + id + ')'
    },
  }
})

export const { refetch } = tourcodeSlice.actions

export default tourcodeSlice.reducer

export const {
  selectAll: selectAllTourcode,
  selectById: selectTourcodeById,
  selectIds: selectTourcodeIds,
  //} = tourcodeAdapter.getSelectors(state => state.tourcode)
} = tourcodeAdapter.getSelectors(state => state.tourcode)