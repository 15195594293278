import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'

import { adderClosed, editorOpened } from '../app/appSlice'
import { addNewCustomers } from './customersSlice'
import { adderLogger } from '../../helpers/LoggerMsg'

import Grid from '@material-ui/core/Grid'
import DialogActions from '@material-ui/core/DialogActions'

import { UncontrolledFreeField } from '../../components/ucfields/UncontrolledFreeField'
import { AddButton, CloseButton } from '../../components/FunctionalButtons'

export const AdderP1 = () => {
    const [addRequestStatus, setAddRequestStatus] = useState('idle')

    const dispatch = useDispatch()
    const canSave = addRequestStatus === 'idle'

    const [primary_name, set_primary_name] = useState('')
    const [email, set_email] = useState('')

    const addEntry = async () => {
        if (canSave) {
            try {
                setAddRequestStatus('pending')
                const resultAction = await dispatch(
                    addNewCustomers({
                        primary_name: primary_name ? primary_name : null,
                        email: email ? email : null,
                    })
                )
                unwrapResult(resultAction)
                dispatch(adderClosed(adderLogger(['success'])))
                dispatch(editorOpened({ 'editor': resultAction.payload.id }))
            } catch (err) {
                dispatch(adderClosed(adderLogger(['error', err])))
            } finally {
                setAddRequestStatus('idle')
            }
        }
    }

    return (<Grid container spacing={3}>

        <Grid item xs={12} sm={12}>
            <UncontrolledFreeField
                label="Name"
                helperText="Please fill the customer name."
                autoFocus
                customs={{
                    value: primary_name,
                    onChange: (event) => { set_primary_name(event.target.value) },
                }}
            />
        </Grid>
        <Grid item xs={12} sm={12}>
            <UncontrolledFreeField
                label="Email"
                helperText="Please fill the email address."
                customs={{
                    value: email,
                    onChange: (event) => { set_email(event.target.value) },
                }}
            />
        </Grid>
        <Grid item xs={12}>
            <DialogActions>
                <CloseButton canParentSave={canSave} />
                <AddButton onClick={addEntry} />
            </DialogActions>
        </Grid>

    </Grid>
    )
}