import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { selectAllWHT, fetchWHT } from './whtSlice'
//import { whtType } from '../../components/SelectOptions'
import { editorOpened } from '../app/appSlice'

import { DataGrid } from '@material-ui/data-grid'

import useMediaQuery from '@material-ui/core/useMediaQuery'

import { NumFormatDisplay, PercentageDisplay } from '../../components/NumberFormatCustom'
import { HorizontalLoadingOverlay } from '../../components/HorizontalLoadingOverlay'

import { useStyles } from '../../styles/wht/Styles.DataList'

export const WHTPage = (props) => {
    const classes = useStyles()
    const isMobile = useMediaQuery('(max-width:1024px)')

    const dispatch = useDispatch()
    const wht = useSelector(selectAllWHT)

    const whtStatus = useSelector(state => state.wht.status)
    const error = useSelector(state => state.wht.error)

    const fetch = props.fetch

    useEffect(() => {
        if (whtStatus === 'idle') {
            dispatch(fetchWHT({
                useOption: true,
                options: fetch,
                //start_date: '',
                //end_date: '',
            }))
        }
    }, [whtStatus, dispatch])

    let content
    let columns

    if (isMobile) {
        columns = [
            {
                field: 'virtual_id',
                headerName: '#',
                width: 50,
                disableColumnMenu: true,
                sortable: false,
                valueGetter: (params) => (
                    params.rowIndex + 1
                )
            },
            {
                field: 'entry_date',
                headerName: 'วันที่ หัก ณ ที่จ่าย',
                width: 160,
                type: 'date',
                valueFormatter: (params) => (
                    new Date(params.getValue('entry_date')).toLocaleString("en-US", { year: 'numeric', month: 'short', day: 'numeric' })
                )
            },
            {
                field: 'suppliers_name',
                headerName: 'ชื่อผู้ถูกหักภาษี ณ ที่จ่าย',
                width: 250,
                disableColumnMenu: true,
                sortable: false,
            },
            {
                field: 'type_of_assessable_income',
                headerName: 'ประเภทเงินได้',
                width: 170,
                disableColumnMenu: true,
                sortable: false
            },
            {
                field: 'amount_paid',
                headerName: 'จำนวนเงินรวม',
                type: 'number',
                width: 130,
                disableColumnMenu: true,
                sortable: false,
                valueFormatter: (params) => (
                    NumFormatDisplay(params.getValue('amount_paid'))
                )
            },
            {
                field: 'vat_percentage',
                headerName: 'VAT (%)',
                type: 'number',
                width: 100,
                disableColumnMenu: true,
                sortable: false,
                renderCell: (params) => (
                    PercentageDisplay(params.getValue('vat_percentage'))
                )
            },
            {
                field: 'wht_percentage',
                headerName: 'WHT (%)',
                type: 'number',
                width: 100,
                disableColumnMenu: true,
                sortable: false,
                renderCell: (params) => (
                    PercentageDisplay(params.getValue('wht_percentage'))
                )
            },
            {
                field: 'book_no',
                headerName: 'เล่มที่/เลขที่',
                width: 110,
                disableColumnMenu: true,
                sortable: false
            },
            {
                field: 'order_no',
                headerName: 'ลำดับที่',
                width: 110,
                disableColumnMenu: true,
                sortable: false
            },
        ]
    } else {
        columns = [
            {
                field: 'virtual_id',
                headerName: '#',
                width: 50,
                disableColumnMenu: true,
                sortable: false,
                valueGetter: (params) => (
                    params.rowIndex + 1
                )
            },
            {
                field: 'entry_date',
                headerName: 'วันที่ หัก ณ ที่จ่าย',
                width: 160,
                type: 'date',
                valueFormatter: (params) => (
                    new Date(params.getValue('entry_date')).toLocaleString("en-US", { year: 'numeric', month: 'short', day: 'numeric' })
                )
            },
            {
                field: 'suppliers_name',
                headerName: 'ชื่อผู้ถูกหักภาษี ณ ที่จ่าย',
                flex: 10,
                disableColumnMenu: true,
                sortable: false,
            },
            {
                field: 'type_of_assessable_income',
                headerName: 'ประเภทเงินได้',
                width: 170,
                disableColumnMenu: true,
                sortable: false
            },
            {
                field: 'amount_paid',
                headerName: 'จำนวนเงินรวม',
                type: 'number',
                width: 130,
                disableColumnMenu: true,
                sortable: false,
                valueFormatter: (params) => (
                    NumFormatDisplay(params.getValue('amount_paid'))
                )
            },
            {
                field: 'vat_percentage',
                headerName: 'VAT (%)',
                type: 'number',
                width: 100,
                disableColumnMenu: true,
                sortable: false,
                renderCell: (params) => (
                    PercentageDisplay(params.getValue('vat_percentage'))
                )
            },
            {
                field: 'wht_percentage',
                headerName: 'WHT (%)',
                type: 'number',
                width: 100,
                disableColumnMenu: true,
                sortable: false,
                renderCell: (params) => (
                    PercentageDisplay(params.getValue('wht_percentage'))
                )
            },
            {
                field: 'book_no',
                headerName: 'เล่มที่/เลขที่',
                width: 110,
                disableColumnMenu: true,
                sortable: false
            },
            {
                field: 'order_no',
                headerName: 'ลำดับที่',
                width: 110,
                disableColumnMenu: true,
                sortable: false
            },
        ]
    }

    const rows = wht

    content = <div className={classes.root} style={{ height: 400, width: '100%' }}>

        <DataGrid
            autoHeight
            sortingOrder={['asc', 'desc']}
            rows={rows}
            columns={columns}
            pageSize={100}
            density="compact"
            rowHeight={48}
            disableMultipleSelection={true}
            hideFooterSelectedRowCount={true}
            onRowClick={(params) => dispatch(editorOpened({ 'editor': params.row.id }))}
            components={{
                LoadingOverlay: HorizontalLoadingOverlay,
            }}
            loading={Boolean(whtStatus === 'loading')}
        />

    </div>

    return (
        <>
            {content}
        </>
    )
}