import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function Snacks() {
  const classes = useStyles();

  const appLogger = useSelector(state => state.app.logger)

  const [open, setOpen] = useState(false);

  const didMountRef = useRef(false)
  useEffect(() => {
    if (didMountRef.current && appLogger !== false) {
      setOpen(true)
    } else {
      didMountRef.current = true
    }
  }, [appLogger])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Snackbar open={Boolean(open)} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={appLogger[1]}>
          {appLogger[0]}
        </Alert>
      </Snackbar>
    </div>
  );
}