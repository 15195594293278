import { makeStyles } from '@material-ui/core/styles'
import { summary as mainColor } from '../Styles.Color'

const tableColor = mainColor

export const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiDataGrid-colCellTitle": {
            textAlign: "center",
            width: "100%",
            fontWeight: "bold",
        },
        '& .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none',
        },
        "& [data-field=virtual_id], [data-field=entry_date]" : {
            textAlign: "center",
        },
        "& .MuiDataGrid-columnsContainer": {
            color: "#fff",
            backgroundColor: tableColor,
            border: `1px solid ${tableColor}`,
        },
        "& 	.MuiDataGrid-cell": {
            border: `solid ${tableColor}`,
            borderWidth: "0 1px 1px 0",
        },
        "& 	.LastChild": {
            border: `solid ${tableColor}`,
            borderWidth: "0 0 1px 0",
        },
        "& .MuiDataGrid-columnSeparator": {
            visibility: "hidden",
        },

        "& .MuiDataGrid-row": {
            border: `solid ${tableColor}`,
            borderWidth: "0 0 0 1px",
        },
        "& .MuiDataGrid-dataContainer": {
            border: `solid ${tableColor}`,
            borderWidth: "0 1px 0 0",
        },
        "& .MuiDataGrid-root": {
            border: "0",
        },
        "& .align-to-center": {
            padding: "0",
        },
        "& .align-to-center .MuiDataGrid-colCellTitle": {
            display: 'flex',
            verticalAlign: 'middle',
            alignItems: 'center',
        },
        "& [data-field=virtual_id]" : {
            fontWeight: "bold",
        },
        "& .type-organizing-1": {
            color: "#FF0000",
        },
        "& .type-organizing-2": {
            color: "#4169E1",
        },
        "& .type-organizing-3": {
            color: "#00BFFF",
        },
        "& .type-organizing-4": {
            color: "#7FFFD4",
        },
        "& .type-organizing-5": {
            color: "#5F9EA0",
        },
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));