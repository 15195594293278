import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { refetch } from '../../features/invoices/invoicesSlice'
import { adderOpened } from '../../features/app/appSlice'

import { Adder } from '../../features/invoices/Adder'
import { InvoicesPage } from '../../features/invoices/InvoicesPage'
import { Editor } from '../../features/invoices/Editor'
import { Remover } from '../../features/invoices/Remover'

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button'

const fetchOptions = [
    { value: 'show_all', label: 'Show All' },
]

export default function Invoices() {
    const [fetcher, setFetcher] = useState('show_all');

    const dispatch = useDispatch()

    const handleChange = (e) => {
        setFetcher(e.target.value);
        dispatch(refetch())
    }

    return (
        <div>
            <Adder />
            <Editor />
            <Remover />

            <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="flex-end"
                container
                spacing={3}
            >
                <Grid item xs={12} sm={2}>
                    <TextField
                        label="Fetch Options"
                        size="small"
                        variant="outlined"
                        fullWidth
                        select
                        value={fetcher}
                        onChange={handleChange}
                    >
                        {fetchOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Button variant="contained" size="large" fullWidth onClick={() => dispatch(adderOpened({ 'adder': 1 }))}>
                        Add Entry
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    <InvoicesPage fetch={fetcher} />
                </Grid>
            </Grid>
        </div>
    )

}