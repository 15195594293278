import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'

import { saveTourcode } from './tourcodeSlice'

import { DateField } from '../../components/cfields/DateField'
import { FreeField } from '../../components/cfields/FreeField'
import { SelectorField } from '../../components/cfields/SelectorField'

import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

export const CustomField = (props) => {
    const { customs, ...others } = props
    const { type, id, propkey, propval, options, formatter } = customs

    const [value, setValue] = useState(propval || '')
    const [inputValue, setInputValue] = useState(propval || '')
    const [addRequestStatus, setAddRequestStatus] = useState('idle')

    const saveValue = type != 'selector' ? value : inputValue

    const dispatch = useDispatch()

    const handleValue = e => { setValue(e.target.value) }
    const handleValueSelector = (event, newValue) => { setValue(newValue) }
    const handleInputValue = (event, newInputValue) => { setInputValue(newInputValue) }

    const canSave = Boolean(props.editable == 'true')

    const instantSave = async () => {
        if (canSave) {
            try {
                setAddRequestStatus('pending')
                const resultAction = await dispatch(
                    saveTourcode({
                        id: id,
                        [propkey]: saveValue ? saveValue : null
                    })
                )
                unwrapResult(resultAction)
            } catch (err) {
                setValue(propval || '')
            } finally {
                setAddRequestStatus('idle')
            }
        }
    }

    switch (type) {
        case 'date':
            return (
                <DateField
                    value={value}
                    onChange={setValue}
                    onBlur={instantSave}
                    {...others}
                />
            )
        case 'freestyle':
            return (
                <FreeField
                    value={value}
                    onChange={handleValue}
                    onBlur={instantSave}
                    formatter={formatter}
                    {...others}
                />
            )
        case 'selector':
            return (
                <SelectorField
                    customs={{
                        value: value,
                        inputValue: inputValue,
                        onChange: handleValueSelector,
                        onInputChange: handleInputValue,
                        options: options,
                    }}
                    onBlur={instantSave}
                    {...others}
                />
            )
        case 'richtext':
            return (
                <CKEditor
                    editor={ClassicEditor}
                    disabled={!canSave}
                    //data="<p>Hello from CKEditor 5!</p>"
                    data={value}
                    onReady={editor => {
                        // [Comment] You can store the "editor" and use when it is needed.
                        //console.log('Editor is ready to use!', editor)
                        editor.editing.view.change(writer => {
                            writer.setStyle(
                                "min-height",
                                "200px",
                                editor.editing.view.document.getRoot()
                            )
                        })
                    }}
                    onChange={(event, editor) => {
                        const data = editor.getData()
                        setValue(data)
                        //console.log({ event, editor, data })
                    }}
                    onBlur={(event, editor) => {
                        instantSave()
                        //console.log('Blur.', editor)
                    }}
                    onFocus={(event, editor) => {
                        //console.log('Focus.', editor)
                    }}
                />
            )
        default:
            return (
                <FreeField
                    value={value}
                    onChange={handleValue}
                    onBlur={instantSave}
                    formatter={formatter}
                    {...others}
                />
            )
    }
}