import Grid from '@material-ui/core/Grid'

import { OptionsFetcher, typeOfAssessableIncomeSelection } from '../../components/options/Options'
import { NumFormatInput } from '../../components/NumberFormatCustom'

import { CustomField } from './CustomField'

export const EditorP1 = (props) => {
    const {
        id,
        entry_date,
        suppliers_name,
        type_of_assessable_income,
        amount_paid,
        vat_percentage,
        wht_percentage,
        book_no,
        order_no,
        //vat_amount,
        //wht_deducted,
        //amount_transfer,
        single_entry,
        entry_remark,
    } = props.wht ? props.wht : ''

    const editable = props.editable

    return (<Grid container spacing={3}>
        <Grid item xs={12} sm={6}><CustomField label="วันที่ หัก ณ ที่จ่าย" customs={{ type: 'date', id: id, propkey: 'entry_date', propval: entry_date, }} editable={editable} /></Grid>

        <Grid item xs={12} sm={6}><CustomField label="ชื่อผู้ถูกหักภาษี ณ ที่จ่าย" customs={{ type: 'selector', id: id, propkey: 'suppliers_name', propval: suppliers_name, options: OptionsFetcher({ 'slug': 'suppliers_name' }) }} editable={editable} /></Grid>

        <Grid item xs={12} sm={6}><CustomField label="ประเภทเงินได้" customs={{ type: 'selector', id: id, propkey: 'type_of_assessable_income', propval: type_of_assessable_income, options: typeOfAssessableIncomeSelection }} editable={editable} /></Grid>

        <Grid item xs={12} sm={6}><CustomField label="จำนวนเงินรวม" customs={{ type: 'freestyle', id: id, propkey: 'amount_paid', propval: amount_paid, formatter: NumFormatInput }} editable={editable} /></Grid>
        <Grid item xs={12} sm={6}><CustomField label="VAT (%)" customs={{ type: 'freestyle', id: id, propkey: 'vat_percentage', propval: vat_percentage, formatter: NumFormatInput }} editable={editable} /></Grid>
        <Grid item xs={12} sm={6}><CustomField label="WHT (%)" customs={{ type: 'freestyle', id: id, propkey: 'wht_percentage', propval: wht_percentage, formatter: NumFormatInput }} editable={editable} /></Grid>

        <Grid item xs={12} sm={6}><CustomField label="เล่มที่/เลขที่" customs={{ type: 'freestyle', id: id, propkey: 'book_no', propval: book_no, }} editable={editable} /></Grid>
        <Grid item xs={12} sm={6}><CustomField label="ลำดับที่" customs={{ type: 'freestyle', id: id, propkey: 'order_no', propval: order_no, }} editable={editable} /></Grid>

        <Grid item xs={12} sm={6}><CustomField label="Single Entry" disabled customs={{ type: 'freestyle', id: id, propval: single_entry, propval: 'Cost of Sales', }} editable={editable} /></Grid>
        <Grid item xs={12} sm={6}><CustomField label="Remarks" multiline rows={5} customs={{ type: 'freestyle', id: id, propkey: 'entry_remark', propval: entry_remark, }} editable={editable} /></Grid>
    </Grid>
    )
}