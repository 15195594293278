import Grid from '@material-ui/core/Grid'

import { OptionsFetcher, ReceiptNoSelection, revisionSelection } from '../../components/options/Options'

import { CustomField } from './CustomField'

export const EditorP1 = (props) => {
    const {
        id,
        //bookkeeper_id,
        invoice_no,
        created_date,
        receipt_no,
        reference,
        single_entry,
        customer_name,

    } = props.receipts ? props.receipts : ''

    const editable = props.editable

    return (<Grid container spacing={3}>

        <Grid item xs={12} sm={6}><CustomField label="Receipt Date" customs={{ type: 'date', id: id, propkey: 'created_date', propval: created_date, }} editable={editable} /></Grid>
        <Grid item xs={12} sm={6}><CustomField label="Customer Name" customs={{ type: 'selector', id: id, propkey: 'customer_name', propval: customer_name, options: OptionsFetcher({ 'slug': 'customer_name_invoiced' }) }} editable={editable} /></Grid>
        <Grid item xs={12} sm={6}><CustomField label="Invoice No." customs={{ type: 'selector', id: id, propkey: 'invoice_no', propval: invoice_no, options: OptionsFetcher({ 'slug': 'issued_invoices', 'filter_by': customer_name }) }} editable={editable} /></Grid>
        <Grid item xs={12} sm={6}><CustomField label="Receipt No." customs={{ type: 'selector', id: id, propkey: 'receipt_no', propval: receipt_no, options: ReceiptNoSelection(created_date) }} editable={editable} /></Grid>

        <Grid item xs={12} sm={6}><CustomField label="Reference" customs={{ type: 'selector', id: id, propkey: 'reference', propval: reference, options: revisionSelection }} editable={editable} /></Grid>
        <Grid item xs={12} sm={6}><CustomField label="Single Entry" disabled customs={{ type: 'freestyle', id: id, propkey: 'single_entry', propval: 'Sales Revenue', }} editable={editable} /></Grid>

    </Grid>
    )
}