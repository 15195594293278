import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'

import { adderClosed, editorOpened } from '../app/appSlice'
import { addNewTourcode } from './tourcodeSlice'
import { adderLogger } from '../../helpers/LoggerMsg'

import Grid from '@material-ui/core/Grid'
import DialogActions from '@material-ui/core/DialogActions'

import { OptionsFetcher, tourCategorySelection } from '../../components/options/Options'

import { UncontrolledFreeField } from '../../components/ucfields/UncontrolledFreeField'
import { UncontrolledDateField } from '../../components/ucfields/UncontrolledDateField'
import { UncontrolledSelectorField } from '../../components/ucfields/UncontrolledSelectorField'
import { AddButton, CloseButton } from '../../components/FunctionalButtons'

export const AdderP1 = () => {
    const [addRequestStatus, setAddRequestStatus] = useState('idle')

    const dispatch = useDispatch()
    const canSave = addRequestStatus === 'idle'

    const [entry_date, set_entry_date] = useState(new Date())
    const [tour_code, set_tour_code] = useState('')
    const [entry_desc, set_entry_desc] = useState('')
    const [entry_customer, set_entry_customer] = useState('')
    const [category, set_category] = useState('Inbound')

    const [input_entry_customer, set_input_entry_customer] = useState('')
    const [input_category, set_input_category] = useState('')

    function generate(n) {
        var add = 1, max = 12 - add;   // 12 is the min safe number Math.random() can generate without it starting to pad the end with zeros.   

        if (n > max) {
            return generate(max) + generate(n - max);
        }

        max = Math.pow(10, n + add);
        var min = max / 10; // Math.pow(10, n) basically
        var number = Math.floor(Math.random() * (max - min + 1)) + min;

        return ("" + number).substring(add);
    }

    const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
    useEffect(() => {
        set_tour_code(entry_date.getFullYear().toString().substring(2) + months[entry_date.getMonth()] + entry_date.getDate().toString().padStart(2, "0") + "-" + generate(2))
    }, [entry_date])

    // console.log(tour_code) // check for bug

    const addEntry = async () => {
        if (canSave) {
            try {
                setAddRequestStatus('pending')
                const resultAction = await dispatch(
                    addNewTourcode({
                        entry_date: entry_date ? entry_date : null,
                        tour_code: tour_code ? tour_code : null,
                        entry_desc: entry_desc ? entry_desc : null,
                        entry_customer: input_entry_customer ? input_entry_customer : null,
                        category: input_category ? input_category : null,
                    })
                )
                unwrapResult(resultAction)
                dispatch(adderClosed(adderLogger(['success'])))
                dispatch(editorOpened({ 'editor': resultAction.payload.id }))
            } catch (err) {
                dispatch(adderClosed(adderLogger(['error', err])))
            } finally {
                setAddRequestStatus('idle')
            }
        }
    }

    return (<Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
            <UncontrolledDateField
                label="Date"
                helperText="Select a date for the current entry."
                customs={{
                    value: entry_date,
                    onChange: set_entry_date,
                }}
            />
        </Grid>
        <Grid item xs={12} sm={6}>
            <UncontrolledSelectorField
                label="Tour Category"
                helperText="Select a tour category."
                customs={{
                    value: category,
                    inputValue: input_category,
                    onChange: (event, newValue) => { set_category(newValue) },
                    onInputChange: (event, newInputValue) => { set_input_category(newInputValue) },
                    options: tourCategorySelection,
                }}
            />
        </Grid>
        <Grid item xs={12} sm={12}>
            <UncontrolledFreeField
                label="Description"
                helperText="Please fill in the description of this tour program."
                autoFocus
                customs={{
                    value: entry_desc,
                    onChange: (event) => { set_entry_desc(event.target.value) },
                }}
            />
        </Grid>
        <Grid item xs={12} sm={12}>
            <UncontrolledSelectorField
                label="Customer Name"
                helperText="The customer name must be unique."
                customs={{
                    value: entry_customer,
                    inputValue: input_entry_customer,
                    onChange: (event, newValue) => { set_entry_customer(newValue) },
                    onInputChange: (event, newInputValue) => { set_input_entry_customer(newInputValue) },
                    options: OptionsFetcher({ 'slug': 'customer_name' }),
                }}
            />
        </Grid>
        <Grid item xs={12}>
            <DialogActions>
                <CloseButton canParentSave={canSave} />
                <AddButton onClick={addEntry} />
            </DialogActions>
        </Grid>

    </Grid>
    )
}