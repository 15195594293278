import { useDispatch } from 'react-redux'

import { adderOpened } from '../app/appSlice'

import { Adder as AdderBk } from '../bk/Adder'

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button'

export function AddFuelEntry() {
    const dispatch = useDispatch()

    return (
        <div>
            <AdderBk prefilled={{ entry_title: 'Office Expenses', entry_desc: 'ค่าน้ำมันรถกรรมการ' }} />

            <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="flex-end"
                spacing={3}
            >
                <Grid item xs={12}>
                    <Button variant="contained" size="large" color="secondary" onClick={() => dispatch(adderOpened({ 'adder': 1 }))}>
                        Add Fuel Cost
                    </Button>
                </Grid>
            </Grid>
        </div>
    )
}