import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../features/auth/authSlice'
import appReducer from '../features/app/appSlice'

import summaryReducer from '../features/summary/summarySlice'
import bkReducer from '../features/bk/bkSlice'
import customersReducer from '../features/customers/customersSlice'
import tourcodeReducer from '../features/tourcode/tourcodeSlice'
import suppliersReducer from '../features/suppliers/suppliersSlice'
import invoicesReducer from '../features/invoices/invoicesSlice'
import receiptsReducer from '../features/receipts/receiptsSlice'
import whtReducer from '../features/wht/whtSlice'

export default configureStore({
  reducer: {
    auth: authReducer,
    app: appReducer,
    summary: summaryReducer,
    bk: bkReducer,
    customers: customersReducer,
    tourcode: tourcodeReducer,
    suppliers: suppliersReducer,
    invoices: invoicesReducer,
    receipts: receiptsReducer,
    wht: whtReducer,
  },
});
