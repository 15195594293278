import { createSlice, createAsyncThunk, createSelector, createEntityAdapter } from '@reduxjs/toolkit'
import { client } from '../../api/client'

const suppliersAdapter = createEntityAdapter({
  //sortComparer: (a, b) => b.entry_date.localeCompare(a.entry_date),
})

const initialState = suppliersAdapter.getInitialState({
  status: 'idle',
  error: null,
  logger: '',
})

export const fetchSuppliers = createAsyncThunk(
  'suppliers/fetchSuppliers',
  async (props, thunkAPI) => {
    if (props.useOption != '') {
      const options = '/' + props.options
      const response = await client.get(
        '../../api/suppliers' + options,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )

      return response.suppliers
    }
    const response = await client.get('../../api/suppliers')
    return response.suppliers
  }
)

export const addNewSuppliers = createAsyncThunk(
  'suppliers/addNewSuppliers',
  async (initialData, { rejectWithValue }) => {
    try {
      const response = await client.post(
        '../../api/suppliers/add',
        {
          suppliers: initialData
        },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      return response.suppliers
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const saveSuppliers = createAsyncThunk(
  'suppliers/saveSuppliers',
  async (initialData, { rejectWithValue }) => {
    try {
      const response = await client.post(
        '../../api/suppliers/save',
        {
          suppliers: initialData
        },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      return response.suppliers
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const deleteSuppliers = createAsyncThunk(
  'suppliers/deleteSuppliers',
  async (initialData, { rejectWithValue }) => {
    try {
      const response = await client.post(
        '../../api/suppliers/delete',
        {
          suppliers: initialData
        },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      return response.suppliers
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

const suppliersSlice = createSlice({
  name: 'suppliers',
  initialState,
  reducers: {
    refetch: state => {
      state.status = 'idle'
    },
  },
  extraReducers: {
    [fetchSuppliers.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchSuppliers.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      // suppliersAdapter.upsertMany(state, action.payload)
      suppliersAdapter.setAll(state, action.payload)
    },
    [fetchSuppliers.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.payload
      console.log('%cError: Unable to fetch data from the database!', 'color:red')
    },
    [addNewSuppliers.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      suppliersAdapter.addOne(state, action.payload)
    },
    [addNewSuppliers.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = 'Unable to add a new entry! (' + action.error.message + ')'
    },
    [saveSuppliers.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      suppliersAdapter.upsertOne(state, action.payload)
    },
    [saveSuppliers.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = 'Unable to edit this selected entry! (' + action.error.message + ')'
    },
    [deleteSuppliers.fulfilled]: (state, action) => {
      const { id } = action.payload
      state.status = 'succeeded'
      suppliersAdapter.removeOne(state, id)
    },
    [deleteSuppliers.rejected]: (state, action) => {
      const { id } = action.payload
      state.status = 'failed'
      state.error = 'Unable to delete this selected entry! (' + action.error.message + ' | id: ' + id + ')'
    },
  }
})

export const { refetch } = suppliersSlice.actions

export default suppliersSlice.reducer

export const {
  selectAll: selectAllSuppliers,
  selectById: selectSuppliersById,
  selectIds: selectSuppliersIds,
  //} = suppliersAdapter.getSelectors(state => state.suppliers)
} = suppliersAdapter.getSelectors(state => state.suppliers)