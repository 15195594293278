import Grid from '@material-ui/core/Grid'

import { OptionsFetcher, currentStatusSelection, tourCategorySelection } from '../../components/options/Options'
import { TCFormatInput } from '../../components/NumberFormatCustom'

import { CustomField } from './CustomField'

export const EditorP1 = (props) => {
    const {
        id,
        entry_date,
        tour_code,
        entry_desc,
        entry_customer,
        additional_note,
        current_status,
        url_tc,
        category,
        entry_remark,
    } = props.tourcode ? props.tourcode : ''

    const editable = props.editable

    return (<Grid container spacing={3}>

        <Grid item xs={12} sm={3}><CustomField label="Date" customs={{ type: 'date', id: id, propkey: 'entry_date', propval: entry_date, }} editable={editable} /></Grid>
        <Grid item xs={12} sm={3}><CustomField label="Tour Code" customs={{ type: 'freestyle', id: id, propkey: 'tour_code', propval: tour_code, formatter: TCFormatInput }} editable={editable} /></Grid>
        <Grid item xs={12} sm={6}><CustomField label="Customer Name" customs={{ type: 'selector', id: id, propkey: 'entry_customer', propval: entry_customer, options: OptionsFetcher({ 'slug': 'customer_name' }) }} editable={editable} /></Grid>

        <Grid item xs={12} sm={6}><CustomField label="Description" customs={{ type: 'freestyle', id: id, propkey: 'entry_desc', propval: entry_desc, }} editable={editable} /></Grid>
        <Grid item xs={12} sm={3}><CustomField label="Tour Category" customs={{ type: 'selector', id: id, propkey: 'category', propval: category, options: tourCategorySelection }} editable={editable} /></Grid>
        <Grid item xs={12} sm={3}><CustomField label="Current Status" customs={{ type: 'selector', id: id, propkey: 'current_status', propval: current_status, options: currentStatusSelection }} editable={editable} /></Grid>
        <Grid item xs={12} sm={3}><CustomField label="Url" customs={{ type: 'freestyle', id: id, propkey: 'url_tc', propval: url_tc, }} editable={editable} /></Grid>
        <Grid item xs={12} sm={3}><CustomField label="Notes" customs={{ type: 'freestyle', id: id, propkey: 'additional_note', propval: additional_note, }} editable={editable} /></Grid>

        <Grid item xs={12} sm={6}><CustomField label="Remarks" multiline rows={5} customs={{ type: 'freestyle', id: id, propkey: 'entry_remark', propval: entry_remark, }} editable={editable} /></Grid>

    </Grid>
    )
}