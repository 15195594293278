import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { selectAllBk, fetchBk } from './bkSlice'
import { typeOrganizing } from '../../components/options/Options'
import { editorOpened } from '../app/appSlice'

import { DataGrid } from '@material-ui/data-grid'

import useMediaQuery from '@material-ui/core/useMediaQuery'

import { NumFormatDisplay } from '../../components/NumberFormatCustom'
import { HorizontalLoadingOverlay } from '../../components/HorizontalLoadingOverlay'

import { useStyles } from '../../styles/bk/Styles.DataList'

export const BkPage = (props) => {
    const classes = useStyles()
    const isMobile = useMediaQuery('(max-width:1024px)')

    const dispatch = useDispatch()
    const bk = useSelector(selectAllBk)

    const bkStatus = useSelector(state => state.bk.status)
    const error = useSelector(state => state.bk.error)

    const fetch = props.fetch

    useEffect(() => {
        if (bkStatus === 'idle') {
            dispatch(fetchBk({
                useOption: true,
                options: fetch,
                //start_date: '',
                //end_date: '',
            }))
        }
    }, [bkStatus, dispatch])

    let content
    let columns

    if (isMobile) {
        columns = [
            {
                field: 'entry_date',
                headerName: 'Date',
                width: 120,
                type: 'date',
                valueFormatter: (params) => (
                    new Date(params.getValue('entry_date')).toLocaleString("en-US", { year: 'numeric', month: 'short', day: 'numeric' })
                )
            },
            {
                field: 'entry_title',
                headerName: 'Category',
                width: 140,
                sortable: false,
            },
            {
                field: 'entry_desc',
                headerName: 'Desc',
                width: 300,
                disableColumnMenu: true,
                sortable: false,
            },
            {
                field: 'income',
                headerName: 'Income',
                type: 'number',
                width: 120,
                disableColumnMenu: true,
                sortable: false,
                valueFormatter: (params) => (
                    NumFormatDisplay(params.getValue('income'))
                )
            },
            {
                field: 'expense',
                headerName: 'Expense',
                type: 'number',
                width: 120,
                disableColumnMenu: true,
                sortable: false,
                valueFormatter: (params) => (
                    NumFormatDisplay(params.getValue('expense'))
                )
            },
        ]
    } else {
        columns = [
            {
                field: 'virtual_id',
                headerName: '#',
                width: 50,
                disableColumnMenu: true,
                sortable: false,
                cellClassName: (params) => 'type-organizing-' + typeOrganizing[params.getValue('entry_title')],
                valueGetter: (params) => (
                    params.rowIndex + 1
                )
            },
            {
                field: 'entry_date',
                headerName: 'Date',
                width: 120,
                type: 'date',
                valueFormatter: (params) => (
                    new Date(params.getValue('entry_date')).toLocaleString("en-US", { year: 'numeric', month: 'short', day: 'numeric' })
                )
            },
            {
                field: 'entry_title',
                headerName: 'Category',
                width: 195,
                sortable: false,
            },
            {
                field: 'entry_desc',
                headerName: 'Desc',
                flex: 12,
                disableColumnMenu: true,
                sortable: false,
            },
            {
                field: 'income',
                headerName: 'Income',
                type: 'number',
                width: 120,
                disableColumnMenu: true,
                sortable: false,
                valueFormatter: (params) => (
                    NumFormatDisplay(params.getValue('income'))
                )
            },
            {
                field: 'expense',
                headerName: 'Expense',
                type: 'number',
                width: 120,
                disableColumnMenu: true,
                sortable: false,
                valueFormatter: (params) => (
                    NumFormatDisplay(params.getValue('expense'))
                )
            },
        ]
    }

    const rows = bk

    content = <div className={classes.root} style={{ height: 400, width: '100%' }}>

        <DataGrid
            autoHeight
            sortingOrder={['asc', 'desc']}
            rows={rows}
            columns={columns}
            pageSize={100}
            density="compact"
            rowHeight={48}
            disableMultipleSelection={true}
            hideFooterSelectedRowCount={true}
            onRowClick={(params) => dispatch(editorOpened({ 'editor': params.row.id }))}
            components={{
                LoadingOverlay: HorizontalLoadingOverlay,
            }}
            loading={Boolean(bkStatus === 'loading')}
        />

    </div>


    return (
        <>
            {content}
        </>
    )
}