import Grid from '@material-ui/core/Grid'

import { countrySelection } from '../../components/options/Options'
import { TaxFormatInput } from '../../components/NumberFormatCustom'

import { CustomField } from './CustomField'

export const EditorP1 = (props) => {
    const {
        id,
        primary_name,
        official_name,
        email,
        telephone,
        country,
        taxid,
        addr1,
        addr2,
        entry_remark,
    } = props.customers ? props.customers : ''

    const editable = props.editable

    return (<Grid container spacing={3}>

        <Grid item xs={12} sm={6}><CustomField label="Name" customs={{ type: 'freestyle', id: id, propkey: 'primary_name', propval: primary_name, }} editable={editable} /></Grid>
        <Grid item xs={12} sm={6}><CustomField label="Official Name" customs={{ type: 'freestyle', id: id, propkey: 'official_name', propval: official_name, }} editable={editable} /></Grid>
        <Grid item xs={12} sm={6}><CustomField label="Email" customs={{ type: 'freestyle', id: id, propkey: 'email', propval: email, }} editable={editable} /></Grid>
        <Grid item xs={12} sm={6}><CustomField label="Telephone" customs={{ type: 'freestyle', id: id, propkey: 'telephone', propval: telephone, }} editable={editable} /></Grid>

        <Grid item xs={12} sm={6}><CustomField label="Country" customs={{ type: 'selector', id: id, propkey: 'country', propval: country, options: countrySelection }} editable={editable} /></Grid>

        {/* Allowing Non Thai Format */}
        <Grid item xs={12} sm={6}><CustomField label="Tax ID" customs={{ type: 'freestyle', id: id, propkey: 'taxid', propval: taxid, }} editable={editable} /></Grid>
        {/* <Grid item xs={12} sm={6}><CustomField label="Tax ID" customs={{ type: 'freestyle', id: id, propkey: 'taxid', propval: taxid, formatter: TaxFormatInput }} editable={editable} /></Grid> */}
        {/* Allowing Non Thai Format */}

        <Grid item xs={12} sm={6}><CustomField label="Address (Line 1)" customs={{ type: 'freestyle', id: id, propkey: 'addr1', propval: addr1, }} editable={editable} /></Grid>
        <Grid item xs={12} sm={6}><CustomField label="Address (Line 2)" customs={{ type: 'freestyle', id: id, propkey: 'addr2', propval: addr2, }} editable={editable} /></Grid>
        <Grid item xs={12} sm={6}><CustomField label="Remarks" multiline rows={5} customs={{ type: 'freestyle', id: id, propkey: 'entry_remark', propval: entry_remark, }} editable={editable} /></Grid>

    </Grid>
    )
}