import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

export const UncontrolledSelectorField = (props) => {
    const { customs, ...others } = props
    const { value, inputValue, onChange, onInputChange, options } = customs

    return (
        <Autocomplete
            renderInput={(params) =>
                <TextField {...params}
                    size="small"
                    variant="outlined"
                    fullWidth
                    {...others} />}
            freeSolo
            autoHighlight
            value={value}
            inputValue={inputValue}
            onChange={onChange}
            onInputChange={onInputChange}
            options={options}
        />
    )
}