import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'

import { adderClosed, editorOpened } from '../app/appSlice'
import { addNewSummary } from './summarySlice'
import { adderLogger } from '../../helpers/LoggerMsg'

import Grid from '@material-ui/core/Grid'
import DialogActions from '@material-ui/core/DialogActions'

import { OptionsFetcher, categorySelection, entryDescSelection } from '../../components/options/Options'

import { UncontrolledFloatField } from '../../components/ucfields/UncontrolledFloatField'
import { UncontrolledDateField } from '../../components/ucfields/UncontrolledDateField'
import { UncontrolledSelectorField } from '../../components/ucfields/UncontrolledSelectorField'
import { AddButton, CloseButton } from '../../components/FunctionalButtons'

export const AdderP1 = (props) => {
    const { prefilled } = props

    const [addRequestStatus, setAddRequestStatus] = useState('idle')

    const dispatch = useDispatch()
    const canSave = addRequestStatus === 'idle'

    const [entry_date, set_entry_date] = useState(new Date())
    const [entry_title, set_entry_title] = useState( prefilled ? prefilled.entry_title : '' )
    const [entry_desc, set_entry_desc] = useState( prefilled ? prefilled.entry_desc : '' )
    const [income, set_income] = useState('')
    const [expense, set_expense] = useState('')

    const [input_entry_title, set_input_entry_title] = useState('')
    const [input_entry_desc, set_input_entry_desc] = useState('')

    const addEntry = async () => {
        if (canSave) {
            try {
                setAddRequestStatus('pending')
                const resultAction = await dispatch(
                    addNewSummary({
                        entry_date: entry_date ? entry_date : null,
                        entry_title: input_entry_title ? input_entry_title : null,
                        entry_desc: input_entry_desc ? input_entry_desc : null,
                        income: income ? income : null,
                        expense: expense ? expense : null,
                    })
                )
                unwrapResult(resultAction)
                dispatch(adderClosed(adderLogger(['success'])))
                /* It's unnecessary to open editor after this one, since Adder and Editor have the same number of textfields */
                //dispatch(editorOpened({ 'editor': resultAction.payload.id }))
            } catch (err) {
                dispatch(adderClosed(adderLogger(['error', err])))
            } finally {
                setAddRequestStatus('idle')
            }
        }
    }

    return (<Grid container spacing={3}>

        <Grid item xs={12} sm={6}>
            <UncontrolledDateField
                label="Date"
                helperText="Select a date for the current entry."
                customs={{
                    value: entry_date,
                    onChange: set_entry_date,
                }}
            />
        </Grid>
        <Grid item xs={12} sm={6}>
            <UncontrolledSelectorField
                label="Category"
                helperText="Select a category."
                autoFocus
                customs={{
                    value: entry_title,
                    inputValue: input_entry_title,
                    onChange: (event, newValue) => { set_entry_title(newValue) },
                    onInputChange: (event, newInputValue) => { set_input_entry_title(newInputValue) },
                    options: categorySelection,
                }}
            />
        </Grid>
        <Grid item xs={12} sm={12}>
            <UncontrolledSelectorField
                label="Description"
                helperText="Please fill in the title of this entry."
                customs={{
                    value: entry_desc,
                    inputValue: input_entry_desc,
                    onChange: (event, newValue) => { set_entry_desc(newValue) },
                    onInputChange: (event, newInputValue) => { set_input_entry_desc(newInputValue) },
                    options: entryDescSelection,
                }}
            />
        </Grid>
        <Grid item xs={12} sm={6}>
            <UncontrolledFloatField
                label="Income"
                customs={{
                    value: income,
                    onChange: (event) => { set_income(event.target.value) },
                }}
            />
        </Grid>
        <Grid item xs={12} sm={6}>
            <UncontrolledFloatField
                label="Expense"
                customs={{
                    value: expense,
                    onChange: (event) => { set_expense(event.target.value) },
                }}
            />
        </Grid>
        <Grid item xs={12}>
            <DialogActions>
                <CloseButton canParentSave={canSave} />
                <AddButton onClick={addEntry} />
            </DialogActions>
        </Grid>

    </Grid>
    )
}