import Grid from '@material-ui/core/Grid'

import { CustomField } from './CustomField'

export const EditorP2 = (props) => {
    const {
        id,
        telephone,
        license_no,
        entry_remark,
        email,
        account_no,
    } = props.suppliers ? props.suppliers : ''

    const editable = props.editable

    return (<Grid container spacing={3}>

        <Grid item xs={12} sm={6}><CustomField label="Telephone" customs={{ type: 'freestyle', id: id, propkey: 'telephone', propval: telephone, }} editable={editable} /></Grid>
        <Grid item xs={12} sm={6}><CustomField label="Email" customs={{ type: 'freestyle', id: id, propkey: 'email', propval: email, }} editable={editable} /></Grid>

        <Grid item xs={12} sm={6}><CustomField label="Account No." customs={{ type: 'freestyle', id: id, propkey: 'account_no', propval: account_no, }} editable={editable} /></Grid>
        <Grid item xs={12} sm={6}><CustomField label="License No." customs={{ type: 'freestyle', id: id, propkey: 'license_no', propval: license_no, }} editable={editable} /></Grid>

        <Grid item xs={12} sm={6}><CustomField label="Remarks" multiline rows={5} customs={{ type: 'freestyle', id: id, propkey: 'entry_remark', propval: entry_remark, }} editable={editable} /></Grid>

    </Grid>
    )
}