import { NumFormatInput } from '../NumberFormatCustom'
import TextField from '@material-ui/core/TextField'

export const UncontrolledFloatField = (props) => {
    const { customs, ...others } = props
    const { value, onChange } = customs

    return (
        <TextField
            size="small"
            variant="outlined"
            fullWidth
            InputProps={{ inputComponent: NumFormatInput }}
            value={value}
            onChange={onChange}
            {...others}
        />
    )
}