import { createSlice, createAsyncThunk, createSelector, createEntityAdapter } from '@reduxjs/toolkit'
import { client } from '../../api/client'

const receiptsAdapter = createEntityAdapter({
  //sortComparer: (a, b) => b.entry_date.localeCompare(a.entry_date),
})

const initialState = receiptsAdapter.getInitialState({
  status: 'idle',
  error: null,
  logger: '',
})

export const fetchReceipts = createAsyncThunk(
  'receipts/fetchReceipts',
  async (props, thunkAPI) => {
    if (props.useOption != '') {
      const options = '/' + props.options
      const response = await client.get(
        '../../api/receipts' + options,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )

      return response.receipts
    }
    const response = await client.get('../../api/receipts')
    return response.receipts
  }
)

export const addNewReceipts = createAsyncThunk(
  'receipts/addNewReceipts',
  async (initialData, { rejectWithValue }) => {
    try {
      const response = await client.post(
        '../../api/receipts/add',
        {
          receipts: initialData
        },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      return response.receipts
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const saveReceipts = createAsyncThunk(
  'receipts/saveReceipts',
  async (initialData, { rejectWithValue }) => {
    try {
      const response = await client.post(
        '../../api/receipts/save',
        {
          receipts: initialData
        },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      return response.receipts
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const deleteReceipts = createAsyncThunk(
  'receipts/deleteReceipts',
  async (initialData, { rejectWithValue }) => {
    try {
      const response = await client.post(
        '../../api/receipts/delete',
        {
          receipts: initialData
        },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      return response.receipts
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

const receiptsSlice = createSlice({
  name: 'receipts',
  initialState,
  reducers: {
    refetch: state => {
      state.status = 'idle'
    },
  },
  extraReducers: {
    [fetchReceipts.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchReceipts.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      // receiptsAdapter.upsertMany(state, action.payload)
      receiptsAdapter.setAll(state, action.payload)
    },
    [fetchReceipts.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.payload
      console.log('%cError: Unable to fetch data from the database!', 'color:red')
    },
    [addNewReceipts.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      receiptsAdapter.addOne(state, action.payload)
    },
    [addNewReceipts.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = 'Unable to add a new entry! (' + action.error.message + ')'
    },
    [saveReceipts.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      receiptsAdapter.upsertOne(state, action.payload)
    },
    [saveReceipts.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = 'Unable to edit this selected entry! (' + action.error.message + ')'
    },
    [deleteReceipts.fulfilled]: (state, action) => {
      const { id } = action.payload
      state.status = 'succeeded'
      receiptsAdapter.removeOne(state, id)
    },
    [deleteReceipts.rejected]: (state, action) => {
      const { id } = action.payload
      state.status = 'failed'
      state.error = 'Unable to delete this selected entry! (' + action.error.message + ' | id: ' + id + ')'
    },
  }
})

export const { refetch } = receiptsSlice.actions

export default receiptsSlice.reducer

export const {
  selectAll: selectAllReceipts,
  selectById: selectReceiptsById,
  selectIds: selectReceiptsIds,
  //} = receiptsAdapter.getSelectors(state => state.receipts)
} = receiptsAdapter.getSelectors(state => state.receipts)