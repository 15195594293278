import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { useHistory } from 'react-router-dom'

import { deleterClosed, editorClosed } from '../app/appSlice'
import { deleteCustomers } from './customersSlice'
import { deleterLogger } from '../../helpers/LoggerMsg'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import Alert from '@material-ui/lab/Alert'

import Button from '@material-ui/core/Button'

import { FreeField } from '../../components/cfields/FreeField'

// import CustomizedSnackbars from '../../components/Snackbar'

export const Remover = () => {
    const app = useSelector(state => state.app.deleter)

    const [removable, setRemovable] = useState('')

    const [addRequestStatus, setAddRequestStatus] = useState('idle')

    const dispatch = useDispatch()
    const history = useHistory()

    const onAgreeClicked = async () => {
        if (app) {
            try {
                setAddRequestStatus('pending')
                const resultAction = await dispatch(
                    deleteCustomers({
                        id: app
                    })
                )
                unwrapResult(resultAction)
                history.push(`/customers`)
                dispatch(editorClosed())
                dispatch(deleterClosed(deleterLogger(['success'])))
            } catch (err) {
                dispatch(deleterClosed(deleterLogger(['error', err])))
            } finally {
                setRemovable('')
                setAddRequestStatus('idle')
            }
        }
    }

    const onCloseClicked = async () => {
        try {
            setAddRequestStatus('pending')
            dispatch(deleterClosed())
        } catch (err) {
            console.log(err)
        } finally {
            setRemovable('')
            setAddRequestStatus('idle')
        }
    }

    return (
        <div>
            <Dialog
                open={Boolean(app)}
                onClose={onCloseClicked}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title"><Typography variant="h6" component="span" color="textPrimary">Please type 'del' to remove the following entry:</Typography></DialogTitle>
                <DialogContent>
                    <Box mb={3}><FreeField onChange={(e) => setRemovable(e.target.value.toUpperCase())} /></Box>
                    <DialogContentText id="alert-dialog-description" component="div">
                        <Alert severity="warning">The entry {app} will be permanently deleted.</Alert>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCloseClicked}>
                        Cancel
                    </Button>
                    <Button onClick={onAgreeClicked} autoFocus disabled={removable === 'DEL' ? false : true}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}