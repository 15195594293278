import { createSlice, createAsyncThunk, createSelector, createEntityAdapter } from '@reduxjs/toolkit'
import { client } from '../../api/client'

const customersAdapter = createEntityAdapter({
  //sortComparer: (a, b) => b.entry_date.localeCompare(a.entry_date),
})

const initialState = customersAdapter.getInitialState({
  status: 'idle',
  error: null,
  logger: '',
})

export const fetchCustomers = createAsyncThunk(
  'customers/fetchCustomers',
  async (props, thunkAPI) => {
    if (props.useOption != '') {
      const options = '/' + props.options
      const response = await client.get(
        '../../api/customers' + options,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )

      return response.customers
    }
    const response = await client.get('../../api/customers')
    return response.customers
  }
)

export const addNewCustomers = createAsyncThunk(
  'customers/addNewCustomers',
  async (initialData, { rejectWithValue }) => {
    try {
      const response = await client.post(
        '../../api/customers/add',
        {
          customers: initialData
        },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      return response.customers
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const saveCustomers = createAsyncThunk(
  'customers/saveCustomers',
  async (initialData, { rejectWithValue }) => {
    try {
      const response = await client.post(
        '../../api/customers/save',
        {
          customers: initialData
        },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      return response.customers
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const deleteCustomers = createAsyncThunk(
  'customers/deleteCustomers',
  async (initialData, { rejectWithValue }) => {
    try {
      const response = await client.post(
        '../../api/customers/delete',
        {
          customers: initialData
        },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      return response.customers
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    refetch: state => {
      state.status = 'idle'
    },
  },
  extraReducers: {
    [fetchCustomers.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchCustomers.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      // customersAdapter.upsertMany(state, action.payload)
      customersAdapter.setAll(state, action.payload)
    },
    [fetchCustomers.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.payload
      console.log('%cError: Unable to fetch data from the database!', 'color:red')
    },
    [addNewCustomers.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      customersAdapter.addOne(state, action.payload)
    },
    [addNewCustomers.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = 'Unable to add a new entry! (' + action.error.message + ')'
    },
    [saveCustomers.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      customersAdapter.upsertOne(state, action.payload)
    },
    [saveCustomers.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = 'Unable to edit this selected entry! (' + action.error.message + ')'
    },
    [deleteCustomers.fulfilled]: (state, action) => {
      const { id } = action.payload
      state.status = 'succeeded'
      customersAdapter.removeOne(state, id)
    },
    [deleteCustomers.rejected]: (state, action) => {
      const { id } = action.payload
      state.status = 'failed'
      state.error = 'Unable to delete this selected entry! (' + action.error.message + ' | id: ' + id + ')'
    },
  }
})

export const { refetch } = customersSlice.actions

export default customersSlice.reducer

export const {
  selectAll: selectAllCustomers,
  selectById: selectCustomersById,
  selectIds: selectCustomersIds,
  //} = customersAdapter.getSelectors(state => state.customers)
} = customersAdapter.getSelectors(state => state.customers)