import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'

import { adderClosed, editorOpened } from '../app/appSlice'
import { addNewInvoices } from './invoicesSlice'
import { adderLogger } from '../../helpers/LoggerMsg'

import Grid from '@material-ui/core/Grid'
import DialogActions from '@material-ui/core/DialogActions'

import { OptionsFetcher, revisionSelection, referenceSelection } from '../../components/options/Options'

import { UncontrolledFreeField } from '../../components/ucfields/UncontrolledFreeField'
import { UncontrolledDateField } from '../../components/ucfields/UncontrolledDateField'
import { UncontrolledSelectorField } from '../../components/ucfields/UncontrolledSelectorField'
import { AddButton, CloseButton } from '../../components/FunctionalButtons'

export const AdderP1 = () => {
    const [addRequestStatus, setAddRequestStatus] = useState('idle')

    const dispatch = useDispatch()
    const canSave = addRequestStatus === 'idle'

    const [created_date, set_created_date] = useState(new Date())
    const [customer_name, set_customer_name] = useState('')
    const [invoice_no, set_invoice_no] = useState('')
    const [revision, set_revision] = useState('')
    const [reference, set_reference] = useState('')
    const [service_for, set_service_for] = useState('')

    const [input_customer_name, set_input_customer_name] = useState('')
    const [input_invoice_no, set_input_invoice_no] = useState('')
    const [input_revision, set_input_revision] = useState('')
    const [input_reference, set_input_reference] = useState('')

    const addEntry = async () => {
        if (canSave) {
            try {
                setAddRequestStatus('pending')
                const resultAction = await dispatch(
                    addNewInvoices({
                        created_date: created_date ? created_date : null,
                        customer_name: input_customer_name ? input_customer_name : null,
                        invoice_no: input_invoice_no ? input_invoice_no : null,
                        revision: input_revision ? input_revision : null,
                        reference: input_reference ? input_reference : null,
                        service_for: service_for ? service_for : null,
                    })
                )
                unwrapResult(resultAction)
                dispatch(adderClosed(adderLogger(['success'])))
                dispatch(editorOpened({ 'editor': resultAction.payload.id }))
            } catch (err) {
                dispatch(adderClosed(adderLogger(['error', err])))
            } finally {
                setAddRequestStatus('idle')
            }
        }
    }

    return (<Grid container spacing={3}>

        <Grid item xs={12} sm={6}>
            <UncontrolledDateField
                label="Date"
                helperText="Select a date for the current entry."
                customs={{
                    value: created_date,
                    onChange: set_created_date,
                }}
            />
        </Grid>
        <Grid item xs={12} sm={6}>
            <UncontrolledSelectorField
                label="Customer Name"
                helperText="Select the customer name."
                autoFocus
                customs={{
                    value: customer_name,
                    inputValue: input_customer_name,
                    onChange: (event, newValue) => { set_customer_name(newValue) },
                    onInputChange: (event, newInputValue) => { set_input_customer_name(newInputValue) },
                    options: OptionsFetcher({ 'slug': 'customer_name' })
                }}
            />
        </Grid>

        <Grid item xs={12} sm={6}>
            <UncontrolledSelectorField
                label="Invoice No."
                helperText="Select the invoice number from a tourcode number."
                customs={{
                    value: invoice_no,
                    inputValue: input_invoice_no,
                    onChange: (event, newValue) => { set_invoice_no(newValue) },
                    onInputChange: (event, newInputValue) => { set_input_invoice_no(newInputValue) },
                    options: OptionsFetcher({ 'slug': 'tour_code', 'filter_by': customer_name })
                }}
            />
        </Grid>
        <Grid item xs={12} sm={6}>
            <UncontrolledSelectorField
                label="Revision"
                helperText="Select the current revision."
                customs={{
                    value: revision,
                    inputValue: input_revision,
                    onChange: (event, newValue) => { set_revision(newValue) },
                    onInputChange: (event, newInputValue) => { set_input_revision(newInputValue) },
                    options: revisionSelection,
                }}
            />
        </Grid>
        <Grid item xs={12} sm={6}>
            <UncontrolledSelectorField
                label="Reference"
                helperText="Reference is most the quotation number."
                customs={{
                    value: reference,
                    inputValue: input_reference,
                    onChange: (event, newValue) => { set_reference(newValue) },
                    onInputChange: (event, newInputValue) => { set_input_reference(newInputValue) },
                    options: referenceSelection,
                }}
            />
        </Grid>

        <Grid item xs={12} sm={6}>
            <UncontrolledFreeField
                label="Service For"
                helperText="Usually the name of the primary guest."
                customs={{
                    value: service_for,
                    onChange: (event) => { set_service_for(event.target.value) },
                }}
            />
        </Grid>

        <Grid item xs={12}>
            <DialogActions>
                <CloseButton canParentSave={canSave} />
                <AddButton onClick={addEntry} />
            </DialogActions>
        </Grid>

    </Grid>
    )
}