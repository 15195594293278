import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'

import { adderClosed, editorOpened } from '../app/appSlice'
import { addNewWHT } from './whtSlice'
import { adderLogger } from '../../helpers/LoggerMsg'

import Grid from '@material-ui/core/Grid'
import DialogActions from '@material-ui/core/DialogActions'

import { OptionsFetcher } from '../../components/options/Options'

import { UncontrolledDateField } from '../../components/ucfields/UncontrolledDateField'
import { UncontrolledSelectorField } from '../../components/ucfields/UncontrolledSelectorField'
import { AddButton, CloseButton } from '../../components/FunctionalButtons'

export const AdderP1 = () => {
    const [addRequestStatus, setAddRequestStatus] = useState('idle')

    const dispatch = useDispatch()
    const canSave = addRequestStatus === 'idle'

    const [entry_date, set_entry_date] = useState(new Date())
    const [suppliers_name, set_suppliers_name] = useState('')

    const [input_suppliers_name, set_input_suppliers_name] = useState('')

    const addEntry = async () => {
        if (canSave) {
            try {
                setAddRequestStatus('pending')
                const resultAction = await dispatch(
                    addNewWHT({
                        entry_date: entry_date ? entry_date : null,
                        suppliers_name: input_suppliers_name ? input_suppliers_name : null,
                    })
                )
                unwrapResult(resultAction)
                dispatch(adderClosed(adderLogger(['success'])))
                dispatch(editorOpened({ 'editor': resultAction.payload.id }))
            } catch (err) {
                dispatch(adderClosed(adderLogger(['error', err])))
            } finally {
                setAddRequestStatus('idle')
            }
        }
    }

    return (<Grid container spacing={3}>

        <Grid item xs={12} sm={6}>
            <UncontrolledDateField
                label="วันที่ หัก ณ ที่จ่าย"
                helperText="Select a date for the current entry"
                customs={{
                    value: entry_date,
                    onChange: set_entry_date,
                }}
            />
        </Grid>
        <Grid item xs={12} sm={6}>
            <UncontrolledSelectorField
                label="ชื่อผู้ถูกหักภาษี ณ ที่จ่าย"
                helperText="Select the supplier name"
                autoFocus
                customs={{
                    value: suppliers_name,
                    inputValue: input_suppliers_name,
                    onChange: (event, newValue) => { set_suppliers_name(newValue) },
                    onInputChange: (event, newInputValue) => { set_input_suppliers_name(newInputValue) },
                    options: OptionsFetcher({ 'slug': 'suppliers_name' })
                }}
            />
        </Grid>

        <Grid item xs={12}>
            <DialogActions>
                <CloseButton canParentSave={canSave} />
                <AddButton onClick={addEntry} />
            </DialogActions>
        </Grid>

    </Grid>
    )
}