import {
    createSlice,
    createAsyncThunk
} from '@reduxjs/toolkit'

import { client } from '../../api/client'

export const registerUser = createAsyncThunk(
    'auth/registerUser',
    async (initialData, thunkAPI) => {
        try {
            const response = await client.post('../../api/register', { auth: initialData })
            localStorage.setItem('token', response.token)
            // might be better? or not -->
            // Storage.save('token', response)
            // OR
            // Storage.save('token', data.token)
            // return { ...data, username: username, email: email }
            return response
        } catch (err) {
            // if (!err.response) {
            //     throw err
            // }
            return thunkAPI.rejectWithValue("Registration Failed")
        }
    }
)

export const signinUser = createAsyncThunk(
    'auth/signinUser',
    async (initialData, thunkAPI) => {
        try {
            const response = await client.post('../../api/signin', { auth: initialData })
            localStorage.setItem('token', response.token)

            return response
        } catch (err) {
            //console.log('Error', err.response.response)
            //thunkAPI.rejectWithValue(err.response.response)
            return thunkAPI.rejectWithValue("Signin Failed")
        }
    }
)

export const fetchUserBytoken = createAsyncThunk(
    'auth/fetchUserByToken',
    async ({ token }, thunkAPI) => {
        try {
            const response = await client.get('../../api/me',
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                }
            )

            return response
        } catch (err) {
            console.log('Error', err.response.data)
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
)

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        username: '',
        email: '',
        isFetching: false,
        isSuccess: false,
        isError: false,
        errorMessage: '',
    },
    reducers: {
        clearState: (state) => {
            state.isError = false
            state.isSuccess = false
            state.isFetching = false

            return state
        },
    },
    extraReducers: {
        [registerUser.fulfilled]: (state, { payload }) => {
            state.isFetching = false
            state.isSuccess = true
            state.email = payload.email
            state.username = payload.username
        },
        [registerUser.pending]: (state) => {
            state.isFetching = true
        },
        [registerUser.rejected]: (state, action) => {
            state.isFetching = false
            state.isError = true
            state.errorMessage = action.error.message
        },
        [signinUser.fulfilled]: (state, { payload }) => {
            state.email = payload.email
            state.username = payload.username
            state.isFetching = false
            state.isSuccess = true
            //return state
        },
        [signinUser.rejected]: (state, action) => {
            state.isFetching = false
            state.isError = true
            state.errorMessage = action.error.message
        },
        [signinUser.pending]: (state) => {
            state.isFetching = true
        },
        [fetchUserBytoken.pending]: (state) => {
            state.isFetching = true
        },
        [fetchUserBytoken.fulfilled]: (state, { payload }) => {
            state.isFetching = false
            state.isSuccess = true
            state.email = payload.email
            state.username = payload.username
        },
        [fetchUserBytoken.rejected]: (state) => {
            state.isFetching = false
            state.isError = true
        },
    },
})

export default authSlice.reducer

export const { clearState } = authSlice.actions

export const authSelector = (state) => state.auth