import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'

import { adderClosed, editorOpened } from '../app/appSlice'
import { addNewReceipts } from './receiptsSlice'
import { adderLogger } from '../../helpers/LoggerMsg'

import Grid from '@material-ui/core/Grid'
import DialogActions from '@material-ui/core/DialogActions'

import { OptionsFetcher, ReceiptNoSelection, revisionSelection } from '../../components/options/Options'

import { UncontrolledFreeField } from '../../components/ucfields/UncontrolledFreeField'
import { UncontrolledDateField } from '../../components/ucfields/UncontrolledDateField'
import { UncontrolledSelectorField } from '../../components/ucfields/UncontrolledSelectorField'
import { AddButton, CloseButton } from '../../components/FunctionalButtons'

export const AdderP1 = () => {
    const [addRequestStatus, setAddRequestStatus] = useState('idle')

    const dispatch = useDispatch()
    const canSave = addRequestStatus === 'idle'

    const [created_date, set_created_date] = useState(new Date())
    const [customer_name, set_customer_name] = useState('')
    const [invoice_no, set_invoice_no] = useState('')
    const [receipt_no, set_receipt_no] = useState('')
    const [reference, set_reference] = useState('')

    const [input_customer_name, set_input_customer_name] = useState('')
    const [input_invoice_no, set_input_invoice_no] = useState('')
    const [input_receipt_no, set_input_receipt_no] = useState('')
    const [input_reference, set_input_reference] = useState('')

    const addEntry = async () => {
        if (canSave) {
            try {
                setAddRequestStatus('pending')
                const resultAction = await dispatch(
                    addNewReceipts({
                        created_date: created_date ? created_date : null,
                        customer_name: input_customer_name ? input_customer_name : null,
                        invoice_no: input_invoice_no ? input_invoice_no : null,
                        receipt_no: input_receipt_no ? input_receipt_no : null,
                        reference: input_reference ? input_reference : null,
                    })
                )
                unwrapResult(resultAction)
                dispatch(adderClosed(adderLogger(['success'])))
                dispatch(editorOpened({ 'editor': resultAction.payload.id }))
            } catch (err) {
                dispatch(adderClosed(adderLogger(['error', err])))
            } finally {
                setAddRequestStatus('idle')
            }
        }
    }

    return (<Grid container spacing={3}>

        <Grid item xs={12} sm={6}>
            <UncontrolledDateField
                label="Receipt Date"
                helperText="Select the issued date for this receipt."
                customs={{
                    value: created_date,
                    onChange: set_created_date,
                }}
            />
        </Grid>
        <Grid item xs={12} sm={6}>
            <UncontrolledSelectorField
                label="Customer Name"
                helperText="Select the customer name."
                autoFocus
                customs={{
                    value: customer_name,
                    inputValue: input_customer_name,
                    onChange: (event, newValue) => { set_customer_name(newValue) },
                    onInputChange: (event, newInputValue) => { set_input_customer_name(newInputValue) },
                    options: OptionsFetcher({ 'slug': 'customer_name_invoiced' })
                }}
            />
        </Grid>

        <Grid item xs={12} sm={6}>
            <UncontrolledSelectorField
                label="Invoice No."
                helperText="Select the invoice number from a tourcode number."
                customs={{
                    value: invoice_no,
                    inputValue: input_invoice_no,
                    onChange: (event, newValue) => { set_invoice_no(newValue) },
                    onInputChange: (event, newInputValue) => { set_input_invoice_no(newInputValue) },
                    options: OptionsFetcher({ 'slug': 'issued_invoices', 'filter_by': customer_name })
                }}
            />
        </Grid>
        <Grid item xs={12} sm={6}>
            <UncontrolledSelectorField
                label="Receipt No."
                helperText="Fill out the receipt number."
                customs={{
                    value: receipt_no,
                    inputValue: input_receipt_no,
                    onChange: (event, newValue) => { set_receipt_no(newValue) },
                    onInputChange: (event, newInputValue) => { set_input_receipt_no(newInputValue) },
                    options: ReceiptNoSelection(created_date),
                }}
            />
        </Grid>
        <Grid item xs={12} sm={6}>
            <UncontrolledSelectorField
                label="Reference"
                helperText="An optional field."
                customs={{
                    value: reference,
                    inputValue: input_reference,
                    onChange: (event, newValue) => { set_reference(newValue) },
                    onInputChange: (event, newInputValue) => { set_input_reference(newInputValue) },
                    options: revisionSelection,
                }}
            />
        </Grid>

        <Grid item xs={12}>
            <DialogActions>
                <CloseButton canParentSave={canSave} />
                <AddButton onClick={addEntry} />
            </DialogActions>
        </Grid>

    </Grid>
    )
}