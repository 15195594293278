import NumberFormat from 'react-number-format'
//import MaskedInput from 'react-text-mask'

export const NumFormatDisplay = (props) => {
    if (props) {
        // return x.toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
        const options = {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }
        return Number(props).toLocaleString('en-US', options)
    }
    return props
}

export const NumFormatInput = (props) => {
    const { inputRef, onChange, ...other } = props

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    }
                })
            }}
            thousandSeparator
            isNumericString
            decimalScale={2}
            fixedDecimalScale={true}
        />
    )
}

export const TaxFormatDisplay = (props) => {
    const value = props ? props : ''
    return (
        <NumberFormat value={value} displayType={'text'} format="#-####-#####-##-#" />
    )
}

export const TaxFormatInput = (props) => {
    const { inputRef, onChange, ...other } = props

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    }
                })
            }}
            format="#-####-#####-##-#"
            //isNumericString
            fixedDecimalScale={true}
        />
    )
}

export const TCFormatDisplay = (props) => {
    const value = props ? props : ''
    return (
        <NumberFormat value={value} displayType={'text'} format="######-##" />
    )
}

export const TCFormatInput = (props) => {
    const { inputRef, onChange, ...other } = props

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value.replace('-', '').slice(0, 6) + '-' + values.value.replace('-', '').slice(6, 9)
                    }
                })
            }}
            format="######-##"
            //isNumericString
            fixedDecimalScale={true}
        />
    )
}

export const PercentageDisplay = (props) => {
    const value = props ? props : ''
    return (
        <NumberFormat value={value} displayType={'text'} decimalScale={2} fixedDecimalScale={true} suffix={'%'} />
    )
}