import { createSlice, createAsyncThunk, createSelector, createEntityAdapter } from '@reduxjs/toolkit'
import { current } from '@reduxjs/toolkit'

const appAdapter = createEntityAdapter({
  //sortComparer: (a, b) => b.entry_date.localeCompare(a.entry_date),
})

const initialState = appAdapter.getInitialState({
  adder: 0,
  editor: 0,
  deleter: 0,
  logger: [],
  content: [],
})

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    adderOpened: (state, action) => {
      const { adder } = action.payload
      state.adder = adder
    },
    adderClosed: (state, action) => {
      state.adder = 0
      state.logger = action.payload || state.logger
    },
    editorOpened: (state, action) => {
      const { editor } = action.payload
      state.editor = editor
    },
    editorClosed: (state, action) => {
      state.editor = 0
      state.logger = action.payload || state.logger
    },
    deleterOpened: (state, action) => {
      const { deleter } = action.payload
      state.deleter = deleter
    },
    deleterClosed: (state, action) => {
      state.deleter = 0
      state.logger = action.payload || state.logger
    },
    simpleLogger: (state, action) => {
      state.logger = action.payload || state.logger
    },
    contentUpdated: (state, action) => {
      appAdapter.upsertOne(state, action.payload)
      console.log(current(state))
    }
  },
  extraReducers: {
  }
})

export const { editorOpened, editorClosed, deleterOpened, deleterClosed, adderOpened, adderClosed, simpleLogger, contentUpdated } = appSlice.actions

export default appSlice.reducer