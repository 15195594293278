import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { editorClosed, deleterOpened } from '../app/appSlice'
import { selectTourcodeById } from './tourcodeSlice'

import { SaveButton, CancelButton } from '../../components/FunctionalButtons'

import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Switch from '@material-ui/core/Switch'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'

import { TabPanel } from '../../components/TabPanel'
import { EditorP1 } from './EditorP1'
import { EditorP2 } from './EditorP2'

export const Editor = () => {
    const app = useSelector(state => state.app.editor)
    const tourcodeId = app

    const tourcode = useSelector(state => selectTourcodeById(state, tourcodeId))
    const [currentTab, setCurrentTab] = useState(0)

    const [editable, setEditable] = useState(false)
    const [addRequestStatus, setAddRequestStatus] = useState('idle')

    const dispatch = useDispatch()

    const canSave = [tourcodeId].every(Boolean) && tourcodeId != 0 && addRequestStatus === 'idle' && editable === true

    const handleChange = (event, newValue) => {
        setCurrentTab(newValue)
    }

    const handleEditable = (event, newValue) => { setEditable(newValue) }

    return (

        <Dialog
            open={Boolean(tourcodeId)}
            onClose={() => dispatch(editorClosed())}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth="md"
            scroll="body"
        >
            <Tabs
                value={currentTab}
                onChange={handleChange}
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary"
                aria-label="icon label tabs example"
            >
                <Tab icon={<AccountCircleIcon />} label="Tour Code" />
                <Tab icon={<MonetizationOnIcon />} label="BP" />
            </Tabs>

            <DialogTitle disableTypography>
                <Grid container>
                    <Grid item xs={false} sm={2} />
                    <Grid item xs={10} sm={8}>
                        <Typography variant="h4" align="center">Tour Code</Typography>
                    </Grid>
                    <Grid container item xs={2} sm={2} justify="flex-end">
                        <Switch
                            checked={editable}
                            onChange={handleEditable}
                            color="primary"
                        />
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                {/* <DialogContentText>
                    To edit this existing entry, please fill all details including date, category, descriptions, and either income or expense.
                </DialogContentText> */}
                <form noValidate autoComplete="new-password" style={{ padding: 6 }}>
                    <TabPanel currentTab={currentTab} index={0}>
                        <EditorP1 tourcode={tourcode} currentTab={currentTab} editable={editable.toString()} />
                    </TabPanel>
                    <TabPanel currentTab={currentTab} index={1}>
                        <EditorP2 tourcode={tourcode} currentTab={currentTab} editable={editable.toString()} />
                    </TabPanel>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <DialogActions>
                                <Button onClick={() => dispatch(deleterOpened({ 'deleter': tourcodeId }))} disabled={!canSave} >
                                    Delete
                                </Button>
                                <CancelButton canParentSave={canSave}>Save</CancelButton>
                                <SaveButton canParentSave={canSave}>Save</SaveButton>
                            </DialogActions>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </Dialog >
    )
}