import { createSlice, createAsyncThunk, createSelector, createEntityAdapter } from '@reduxjs/toolkit'
import { client } from '../../api/client'

const bkAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.entry_date.localeCompare(b.entry_date),
})

const initialState = bkAdapter.getInitialState({
  status: 'idle',
  error: null,
  logger: '',
})

export const fetchBk = createAsyncThunk(
  'bk/fetchBk',
  async (props, thunkAPI) => {
    if (props.useOption != '') {
      const options = '/' + props.options
      const response = await client.get(
        '../../api/bk' + options,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )

      return response.bk
    }
    const response = await client.get('../../api/bk')
    return response.bk
  }
)

export const addNewBk = createAsyncThunk(
  'bk/addNewBk',
  async (initialData, { rejectWithValue }) => {
    try {
      const response = await client.post(
        '../../api/bk/add',
        {
          bk: initialData
        },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      return response.bk
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const saveBk = createAsyncThunk(
  'bk/saveBk',
  async (initialData, { rejectWithValue }) => {
    try {
      const response = await client.post(
        '../../api/bk/save',
        {
          bk: initialData
        },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      return response.bk
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const deleteBk = createAsyncThunk(
  'bk/deleteBk',
  async (initialData, { rejectWithValue }) => {
    try {
      const response = await client.post(
        '../../api/bk/delete',
        {
          bk: initialData
        },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      return response.bk
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

const bkSlice = createSlice({
  name: 'bk',
  initialState,
  reducers: {
    refetch: state => {
      state.status = 'idle'
    },
  },
  extraReducers: {
    [fetchBk.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchBk.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      // bkAdapter.upsertMany(state, action.payload)
      bkAdapter.setAll(state, action.payload)
    },
    [fetchBk.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.payload
      console.log('%cError: Unable to fetch data from the database!', 'color:red')
    },
    [addNewBk.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      bkAdapter.addOne(state, action.payload)
    },
    [addNewBk.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = 'Unable to add a new entry! (' + action.error.message + ')'
    },
    [saveBk.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      bkAdapter.upsertOne(state, action.payload)
    },
    [saveBk.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = 'Unable to edit this selected entry! (' + action.error.message + ')'
    },
    [deleteBk.fulfilled]: (state, action) => {
      const { id } = action.payload
      state.status = 'succeeded'
      bkAdapter.removeOne(state, id)
    },
    [deleteBk.rejected]: (state, action) => {
      const { id } = action.payload
      state.status = 'failed'
      state.error = 'Unable to delete this selected entry! (' + action.error.message + ' | id: ' + id + ')'
    },
  }
})

export const { refetch } = bkSlice.actions

export default bkSlice.reducer

export const {
  selectAll: selectAllBk,
  selectById: selectBkById,
  selectIds: selectBkIds,
} = bkAdapter.getSelectors(state => state.bk)