import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { paidBySelection, signatureSelection, companyStampSelection, descSelection } from '../../components/options/Options'
import { NumFormatInput } from '../../components/NumberFormatCustom'

import { CustomField } from './CustomField'

import { useStyles } from '../../styles/receipts/Styles.Textfield'

export const EditorP3 = (props) => {
    const classes = useStyles()

    const {
        id,
        customer_name,
        desc01,
        desc02,
        desc03,
        desc04,
        desc05,
        desc06,
        desc07,
        desc08,
        desc09,
        desc10,
        amount01,
        amount02,
        amount03,
        amount04,
        amount05,
        amount06,
        amount07,
        amount08,
        amount09,
        amount10,
        amount_paid,
        currency,
        method_of_payment,
        signature,
        company_stamp,
    } = props.receipts ? props.receipts : ''

    const subtotal = [amount01, amount02, amount03, amount04, amount05, amount06, amount07, amount08, amount09, amount10].map(Number).reduce((a, b) => a + b, 0)
    const finalamount = subtotal - Number(amount_paid)

    const editable = props.editable

    return (<Grid container spacing={3}>

        <Grid item xs={12} sm={6}><CustomField label="Customer Name" disabled customs={{ type: 'freestyle', id: id, propval: customer_name, }} editable={editable} /></Grid>

        <Grid item xs={12} sm={4}><CustomField label="Method of Payment" customs={{ type: 'selector', id: id, propkey: 'method_of_payment', propval: method_of_payment, options: paidBySelection, }} editable={editable} /></Grid>
        <Grid item xs={12} sm={2}><CustomField label="Currency" disabled customs={{ type: 'freestyle', id: id, propval: "THB" }} editable={editable} /></Grid>

        {/* Description & Amount */}
        <Grid container item className={classes.tabular}>
            <Grid container item xs={12} sm={12}>
                <Grid item xs={8} sm={8}><Typography className={classes.header}>Description</Typography></Grid>
                <Grid item xs={4} sm={4}><Typography className={classes.header}>Amount</Typography></Grid>
            </Grid>

            <Grid container item xs={12} sm={12}>
                <Grid item xs={8} sm={8}><CustomField customs={{ type: 'selector', id: id, propkey: 'desc01', propval: desc01, options: descSelection, }} editable={editable} /></Grid>
                <Grid item xs={4} sm={4}><CustomField customs={{ type: 'freestyle', id: id, propkey: 'amount01', propval: amount01, formatter: NumFormatInput }} editable={editable} /></Grid>
            </Grid>

            <Grid container item xs={12} sm={12}>
                <Grid item xs={8} sm={8}><CustomField customs={{ type: 'selector', id: id, propkey: 'desc02', propval: desc02, options: descSelection, }} editable={editable} /></Grid>
                <Grid item xs={4} sm={4}><CustomField customs={{ type: 'freestyle', id: id, propkey: 'amount02', propval: amount02, formatter: NumFormatInput }} editable={editable} /></Grid>
            </Grid>

            <Grid container item xs={12} sm={12}>
                <Grid item xs={8} sm={8}><CustomField customs={{ type: 'selector', id: id, propkey: 'desc03', propval: desc03, options: descSelection, }} editable={editable} /></Grid>
                <Grid item xs={4} sm={4}><CustomField customs={{ type: 'freestyle', id: id, propkey: 'amount03', propval: amount03, formatter: NumFormatInput }} editable={editable} /></Grid>
            </Grid>

            <Grid container item xs={12} sm={12}>
                <Grid item xs={8} sm={8}><CustomField customs={{ type: 'selector', id: id, propkey: 'desc04', propval: desc04, options: descSelection, }} editable={editable} /></Grid>
                <Grid item xs={4} sm={4}><CustomField customs={{ type: 'freestyle', id: id, propkey: 'amount04', propval: amount04, formatter: NumFormatInput }} editable={editable} /></Grid>
            </Grid>

            <Grid container item xs={12} sm={12}>
                <Grid item xs={8} sm={8}><CustomField customs={{ type: 'selector', id: id, propkey: 'desc05', propval: desc05, options: descSelection, }} editable={editable} /></Grid>
                <Grid item xs={4} sm={4}><CustomField customs={{ type: 'freestyle', id: id, propkey: 'amount05', propval: amount05, formatter: NumFormatInput }} editable={editable} /></Grid>
            </Grid>

            <Grid container item xs={12} sm={12}>
                <Grid item xs={8} sm={8}><CustomField customs={{ type: 'selector', id: id, propkey: 'desc06', propval: desc06, options: descSelection, }} editable={editable} /></Grid>
                <Grid item xs={4} sm={4}><CustomField customs={{ type: 'freestyle', id: id, propkey: 'amount06', propval: amount06, formatter: NumFormatInput }} editable={editable} /></Grid>
            </Grid>

            <Grid container item xs={12} sm={12}>
                <Grid item xs={8} sm={8}><CustomField customs={{ type: 'selector', id: id, propkey: 'desc07', propval: desc07, options: descSelection, }} editable={editable} /></Grid>
                <Grid item xs={4} sm={4}><CustomField customs={{ type: 'freestyle', id: id, propkey: 'amount07', propval: amount07, formatter: NumFormatInput }} editable={editable} /></Grid>
            </Grid>

            <Grid container item xs={12} sm={12}>
                <Grid item xs={8} sm={8}><CustomField customs={{ type: 'selector', id: id, propkey: 'desc08', propval: desc08, options: descSelection, }} editable={editable} /></Grid>
                <Grid item xs={4} sm={4}><CustomField customs={{ type: 'freestyle', id: id, propkey: 'amount08', propval: amount08, formatter: NumFormatInput }} editable={editable} /></Grid>
            </Grid>

            <Grid container item xs={12} sm={12}>
                <Grid item xs={8} sm={8}><CustomField customs={{ type: 'selector', id: id, propkey: 'desc09', propval: desc09, options: descSelection, }} editable={editable} /></Grid>
                <Grid item xs={4} sm={4}><CustomField customs={{ type: 'freestyle', id: id, propkey: 'amount09', propval: amount09, formatter: NumFormatInput }} editable={editable} /></Grid>
            </Grid>

            <Grid container item xs={12} sm={12}>
                <Grid item xs={8} sm={8}><CustomField customs={{ type: 'selector', id: id, propkey: 'desc10', propval: desc10, options: descSelection, }} editable={editable} /></Grid>
                <Grid item xs={4} sm={4}><CustomField customs={{ type: 'freestyle', id: id, propkey: 'amount10', propval: amount10, formatter: NumFormatInput }} editable={editable} /></Grid>
            </Grid>
        </Grid>

        <Grid container item xs={12} sm={12} className={classes.tabularbtm}>
            <Grid item xs={12} sm={4}><CustomField label="Total Amount Due" disabled value={subtotal} customs={{ type: 'freestyle', id: id, formatter: NumFormatInput }} editable={editable} /></Grid>
        </Grid>

        <Grid container item xs={12} sm={12} className={classes.tabularbtm}>
            <Grid item xs={12} sm={4}><CustomField label="Amount Paid" customs={{ type: 'freestyle', id: id, propkey: 'amount_paid', propval: amount_paid, formatter: NumFormatInput }} editable={editable} /></Grid>
        </Grid>

        <Grid container item xs={12} sm={12} className={classes.tabularbtm}>
            <Grid item xs={12} sm={4}><CustomField label="Balance Due" disabled value={finalamount} customs={{ type: 'freestyle', id: id, formatter: NumFormatInput }} editable={editable} /></Grid>
        </Grid>
        {/* Payment Information */}

        <Grid item xs={12} sm={6}><CustomField label="Authorized Signature" customs={{ type: 'selector', id: id, propkey: 'signature', propval: signature, options: signatureSelection, }} editable={editable} /></Grid>
        <Grid item xs={12} sm={6}><CustomField label="Company Stamp" customs={{ type: 'selector', id: id, propkey: 'company_stamp', propval: company_stamp, options: companyStampSelection, }} editable={editable} /></Grid>

    </Grid>
    )
}