import Grid from '@material-ui/core/Grid'

import { titleSelection, whtTypeSelection } from '../../components/options/Options'
import { TaxFormatInput } from '../../components/NumberFormatCustom'

import { CustomField } from './CustomField'

export const EditorP1 = (props) => {
    const {
        id,
        primary_name,
        title,
        firstname,
        lastname,
        taxid,
        branch_no,
        wht_type,
    } = props.suppliers ? props.suppliers : ''

    const editable = props.editable

    return (<Grid container spacing={3}>

        <Grid item xs={12} sm={12}><CustomField label="Name" customs={{ type: 'freestyle', id: id, propkey: 'primary_name', propval: primary_name, }} editable={editable} /></Grid>

        <Grid item xs={12} sm={2}><CustomField label="Title" customs={{ type: 'selector', id: id, propkey: 'title', propval: title, options: titleSelection }} editable={editable} /></Grid>
        <Grid item xs={12} sm={5}><CustomField label="First Name" customs={{ type: 'freestyle', id: id, propkey: 'firstname', propval: firstname, }} editable={editable} /></Grid>
        <Grid item xs={12} sm={5}><CustomField label="Last Name" customs={{ type: 'freestyle', id: id, propkey: 'lastname', propval: lastname, }} editable={editable} /></Grid>

        {/* Allowing Only Thai Format */}
        {/* <Grid item xs={12} sm={6}><CustomField label="Tax ID" customs={{ type: 'freestyle', id: id, propkey: 'taxid', propval: taxid, }} editable={editable} /></Grid> */}
        <Grid item xs={12} sm={6}><CustomField label="Tax ID" customs={{ type: 'freestyle', id: id, propkey: 'taxid', propval: taxid, formatter: TaxFormatInput }} editable={editable} /></Grid>
        {/* Allowing Only Thai Format */}

        <Grid item xs={12} sm={3}><CustomField label="Branch No." customs={{ type: 'freestyle', id: id, propkey: 'branch_no', propval: branch_no, }} editable={editable} /></Grid>
        <Grid item xs={12} sm={3}><CustomField label="WHT Type" customs={{ type: 'selector', id: id, propkey: 'wht_type', propval: wht_type, options: whtTypeSelection }} editable={editable} /></Grid>

    </Grid>
    )
}